import { UPDATE_USER_SETTINGS } from "../constants";

const INIT_STATE = {
    user: {},
};

const PlanReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_SETTINGS:
            return {
                ...state,
                user: action.user,
            };
        default:
            return state;
    }
};

export default PlanReducer;

import React from 'react';

function StepIcon(props) {
    const { active, completed } = props;

    const completedStepIcon = () => <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4939 1.31674L6.5606 12.8334L3.39393 9.45007C2.8106 8.90007 1.89393 8.86674 1.22726 9.33341C0.577262 9.81674 0.393928 10.6667 0.793928 11.3501L4.54393 17.4501C4.9106 18.0167 5.54393 18.3667 6.26059 18.3667C6.94393 18.3667 7.59393 18.0167 7.9606 17.4501C8.5606 16.6667 20.0106 3.01674 20.0106 3.01674C21.5106 1.48341 19.6939 0.133406 18.4939 1.30007V1.31674Z" fill="white" />
    </svg>

    const currentStepIcon = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" fill="white" />
    </svg>

    return (
        <div
            style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: active | completed ? 'rgba(7, 96, 234, 1)' : 'rgba(242, 244, 247, 1)',
                color: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: active | completed ? "0px 0px 0px 4px rgba(60, 136, 255, 0.16)" : ''
            }}
        >
            {completed ? completedStepIcon() : currentStepIcon()
            }
        </div>
    );
}

export default StepIcon;
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export default function Integrations() {

    const [connect, setConnect] = useState(false);

    const integrations = [{
        type: 'Wordpress',
        icon: 'https://cdn-icons-png.flaticon.com/512/59/59137.png'
    }];

    return (
        <div>
            <h1>Integration</h1>
            <Grid container>
                {
                    integrations.map((int, index) => {
                        return (
                            <Grid container key={index} alignItems="center" spacing={4}>
                                <Grid item xs={6}>
                                    <img src={int.icon} width={50} alt={int.type} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" onClick={() => connect ? setConnect(false) : setConnect(true)}>{connect ? 'Connect' : 'Disconnect'}</Button>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}

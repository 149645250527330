import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Chip } from "@mui/material";
import Button from "@mui/material/Button";
import Integration from "./integrations";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CustomFileUpload from "../../../../components/forms/custom-elements/customFileUpload";
import { useDispatch } from "react-redux";
import { userOnBoardPost } from "../../../../redux/user/action";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function ConfigurationSettings() {
    const dispatch = useDispatch();
    const [keywords, setKeywords] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [niche, setNiche] = useState([]);
    const [files, setFiles] = useState(null);

    const formMethods = useFormContext();
    const formKeywords = formMethods.watch("keywords");

    function onKeywordDelete(i) {
        const newKeywords = keywords.filter((_, i2) => i2 !== i);

        setKeywords(keywords);

        formMethods.reset(
            {
                ...formMethods.getValues(),
                keywords: newKeywords,
            },
            {
                keepErrors: true,
            }
        );
    }

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const submitForm = () => {
        formMethods.reset(
            {
                ...formMethods.getValues(),
                niche: [...niche],
                competitors: files
            },
            { keepErrors: true }
        );
        dispatch(userOnBoardPost(formMethods.getValues()));
    }

    return (
        <Container sx={{ marginTop: '30px' }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            border: "2px solid #E2E8F0",
                            borderRadius: 1,
                            padding: "26px",
                        }}
                    >
                        <h1>Configurations</h1>

                        <Grid container>

                            <Grid item xs={12} sx={{ paddingBottom: '20px' }}>
                                <Controller
                                    control={formMethods.control}
                                    name="industry"
                                    render={({ field }) => (
                                        <>
                                            <InputLabel>Industry *</InputLabel>
                                            <Select labelId="level-label"
                                                fullWidth
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                {...field}
                                            >
                                                {
                                                    [0, 1].map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)
                                                }
                                            </Select>
                                        </>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ paddingBottom: '20px' }}>
                                <Controller
                                    control={formMethods.control}
                                    name="niche"
                                    render={({ field }) => (
                                        <>
                                            <InputLabel>Niche *</InputLabel>
                                            <Select
                                                multiple
                                                labelId="level-label"
                                                fullWidth
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                value={niche}
                                                onChange={(e) => { console.log(e.code); setNiche(e.target.value) }}
                                            >
                                                {
                                                    [0, 1].map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)
                                                }
                                            </Select>
                                        </>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={formMethods.control}
                                    name="businessSummary"
                                    render={({ field }) => (
                                        <CustomTextField
                                            sx={{ paddingBottom: "20px" }}
                                            label="Business Summary *"
                                            type="textarea"
                                            rows={4}
                                            error={!!formMethods.formState.errors.businessSummary}
                                            helperText={formMethods.formState.errors.businessSummary?.message}
                                            multiline
                                            fullWidth
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>

                                <Grid container spacing={1}>

                                    <Grid item xs={11}>
                                        <Controller
                                            control={formMethods.control}
                                            name="serviceInput"
                                            render={({ field }) => (
                                                <CustomTextField
                                                    value={field.value}
                                                    placeholder="Services"
                                                    sx={{ paddingBottom: "20px" }}
                                                    type="text"
                                                    fullWidth
                                                    label="Services"
                                                    error={!!formMethods.formState.errors.serviceInput}
                                                    helperText={formMethods.formState.errors.serviceInput?.message}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "12px",
                                                        },
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item={2} sx={{ marginTop: '10px' }}>
                                        <Button variant="contained" onClick={toggleModal}>
                                            +
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Modal open={showModal} onClose={toggleModal}>

                                    <Box sx={modalStyle}>
                                        <Controller
                                            control={formMethods.control}
                                            name="serviceUrl"
                                            render={({ field }) => (
                                                <CustomTextField
                                                    value={field.value}
                                                    placeholder="Service URL"
                                                    sx={{ paddingBottom: "20px" }}
                                                    type="text"
                                                    fullWidth
                                                    label="Service URL"
                                                    error={!!formMethods.formState.errors.serviceUrl}
                                                    helperText={formMethods.formState.errors.serviceUrl?.message}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "12px",
                                                        },
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={formMethods.control}
                                            name="serviceName"
                                            render={({ field }) => (
                                                <CustomTextField
                                                    value={field.value}
                                                    placeholder="Service Name"
                                                    sx={{ paddingBottom: "20px" }}
                                                    type="text"
                                                    fullWidth
                                                    label="Service Name"
                                                    error={!!formMethods.formState.errors.serviceName}
                                                    helperText={formMethods.formState.errors.serviceName?.message}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "12px",
                                                        },
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Button variant="contained" onClick={toggleModal}>Save</Button>
                                    </Box>

                                </Modal>
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={formMethods.control}
                                    name="keywordInput"
                                    render={({ field }) => (
                                        <CustomTextField
                                            value={field.value}
                                            placeholder="Programming, Medical, MotorBikes ..."
                                            sx={{ paddingBottom: "20px" }}
                                            type="text"
                                            fullWidth
                                            label="Keywords"
                                            error={!!formMethods.formState.errors.keywords}
                                            helperText={formMethods.formState.errors.keywords ? formMethods.formState.errors.keywords?.message : `Keywords: ${keywords.length}/10`}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                },
                                            }}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.code === "Enter") {
                                                    if (keywords.length === 10) return;
                                                    setKeywords((prev) => [...prev, field.value]);
                                                    formMethods.reset(
                                                        {
                                                            ...formMethods.getValues(),
                                                            keywords: [...keywords, field.value],
                                                            keywordInput: "",
                                                        },
                                                        { keepErrors: true }
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {formKeywords?.map((keyword, i) => (
                                    <Chip key={i} style={{ marginRight: "10px", marginBottom: "5px" }} onDelete={() => onKeywordDelete(i)} label={keyword} />
                                ))}
                            </Grid>

                            <br />
                            <br />
                            <br />

                            <Grid item xs={12}>
                                <CustomFileUpload setFiles={setFiles} />
                            </Grid>

                            <Grid item xs={12}>
                                <br />
                                <br />
                                <Button variant="contained" onClick={submitForm}>Save Settings</Button>
                            </Grid>

                            <br />
                            <br />
                            <br />
                            <br />
                            <Integration />
                        </Grid>
                    </Box>
                </Grid>
            </Paper >
        </Container >
    );
}

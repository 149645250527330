import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';

const YearlySwitch = () => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          sx={{
              height:52,
              width:90,
              
            '& .MuiSwitch-thumb': {
              width: 50, // Adjust the thumb width as needed
              height: 35, // Adjust the thumb height as needed
              borderRadius:12 , // Adjust the border radius as needed
              backgroundColor: '#0760EA', // Desired thumb color
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& .yearly-text': {
                color: 'white', // Text color
                fontSize: 14, // Adjust the font size as needed
                fontWeight: 'bold',
                margin: '0 6px', // Adjust the margin as needed
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: 12, // Adjust the border radius as needed
              background:'#fff',
              border:2,
              borderColor:'#0760EA'
            },
           
          }}
        />
      }
      label={<span className="yearly-text">Yearly</span>}
      labelPlacement="start"
    />
  );
};

export default YearlySwitch;

import React, { useLayoutEffect, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../../redux/industry/action";
import { useFormContext } from "react-hook-form";

export default function SelectIndustry() {
  const formMethods = useFormContext()
  const [industry, setIndustry] = React.useState("");
  const dispatch = useDispatch()
  const industries = useSelector(state => state.IndustryReducer.industries)
  useLayoutEffect(() => {
  dispatch(getIndustries())
  }, [])

  useEffect(()=> {
  console.log(industries)
  console.log('selected  industry: \n',industry)
  console.log('form values: ', formMethods.getValues())
  formMethods.reset(
    {
      ...formMethods.getValues(),
      selectedIndustry: industry
    }
  )
  }, [industries, industry])

  const handleChange = (event) => {
    setIndustry(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="selectIndustryInputLabel">Select Industry</InputLabel>
        <Select
          labelId="selectIndustryInputLabel"
          id="selectIndustrySelect"
          value={industry}
          label="Select Industry"
          onChange={handleChange}
        >
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
          {industries.map((data, index) => (
            <MenuItem value={data}>{data.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import CustomTextField from "../../../components/forms/custom-elements/customTextField";
import { Link, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomCheckbox from "../../../components/forms/custom-elements/customCheckbox";
import CustomSlider from "../../../components/forms/custom-elements/mySlider";
import CustomFormLabel from "../../../components/forms/custom-elements/customFormLabel";
import PageContainer from "../../../components/container/PageContainer";
import { ReactComponent as Logo } from '../../../assets/images/icon/rankify.svg';
import EmailIcon from "../../../assets/images/icon/email.png";
import PasswordIcon from "../../../assets/images/icon/password.png";
import { validateEmail } from "../../../utils/validator/validator";
import Background from "../../../assets/images/icon/Group 48096129.png";
import Child1 from "../../../assets/images/icon/image 2.png";
import { useForm, Controller, FormProvider } from "react-hook-form";

import { blue, green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

//  styles constant
const styles = {
  paperContainer: {
    backgroundImage: `url(${Background})`,
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  heading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  heading2: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "0px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  paragraph: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "40px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  img: {
    textAlign: "center",
    width: "70%",
    height: "70%",
    display: "block",
    margin: "auto",
    marginTop: "30px",
  },
  privacyPolicy: {
    fontSize: "16px",
    color: "#16182B",
  },
  copyRight: {
    fontSize: "16px",
    color: "#718096",
    textDecoration: "none",
  },
};

const Login = ({ signIn, loading, showAlert, fetchedUser, message }) => {
  // console.log(showAlert, message);
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
    color: "white",
  };

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  // const [correct, setCorrect] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (fetchedUser) {
      navigate("/");
    }
  }, [fetchedUser]);

  const handleInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));
  };

  const loginUser = () => {
    if (email && password) {
      if (isValid) {
        signIn(email, password);
      } else {
        setShowError(true);
      }
    }
  };

  return (
    <PageContainer
      title="Login - Rankify-ai App"
      description="Edaly Application Login Page"
    >
      <Grid
        container
        spacing={0}
        sx={{
          height: "100vh",
          background: "white",
        }}
      >
        <Grid item xs={12} sm={12} lg={6} alignSelf="center">
          <Grid
            spacing={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            item
            xs={12}
            lg={12}
            xl={8}
          >
            <Box
              sx={{
                p: 4,
              }}
            >
              <Stack
                style={
                  showError
                    ? { display: "block", marginBottom: "30px" }
                    : { display: "none" }
                }
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert
                  variant="filled"
                  severity="error"
                  style={{ margin: "auto" }}
                >
                  Invalid/Disposable email not allowed
                </Alert>
              </Stack>
              <Stack
                style={
                  showAlert
                    ? { display: "block", marginBottom: "30px" }
                    : { display: "none" }
                }
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert
                  variant="filled"
                  severity="error"
                  style={{ margin: "auto" }}
                >
                  {message}
                </Alert>
              </Stack>
              <Logo style={{ height: '44px', width: '134px' }} alt="logo" />

              <Typography
                color="black"
                variant="h1"
                fontWeight="600"
                fontSize="35px"
                sx={{
                  mr: 1,
                  mt: 2,
                }}
              >
                hi, come join us!
              </Typography>

              <Typography
                color="#718096"
                variant="h3"
                fontWeight="400"
                fontSize="14px"
                sx={{
                  mt: 2,
                }}
              >
                Sign in to get most out of Rankify Ai
              </Typography>

              <Box
                sx={{
                  mt: 5,
                }}
              >
                <FormProvider>
                  <form>
                    <CustomFormLabel
                      style={{
                        fontSize: "14px",
                        color: "#16182B",
                        fontWeight: "600",
                      }}
                      htmlFor="email"
                    >
                      Email
                    </CustomFormLabel>

                    <FormControl fullWidth variant="filled">
                      <InputLabel
                        style={{ display: "none" }}
                        htmlFor="outlined-adornment-amount"
                      >
                        sd
                      </InputLabel>
                      <OutlinedInput
                        id="email"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ marginTop: "-2px" }}
                          >
                            <img src={EmailIcon} alt="icon" />{" "}
                          </InputAdornment>
                        }
                        onChange={handleInputChange}
                        value={email}
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        placeholder="Your Email"
                        style={{ borderRadius: "10px" }}
                      />
                    </FormControl>
                    <br />

                    <CustomFormLabel
                      style={{
                        fontSize: "14px",
                        color: "#16182B",
                        fontWeight: "600",
                      }}
                      htmlFor="password"
                    >
                      Password
                    </CustomFormLabel>

                    <FormControl fullWidth variant="filled">
                      <InputLabel
                        style={{ display: "none" }}
                        htmlFor="outlined-adornment-amount"
                      >
                        sd
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ marginTop: "-2px" }}
                          >
                            <img src={PasswordIcon} alt="icon" />{" "}
                          </InputAdornment>
                        }
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        variant="outlined"
                        fullWidth
                        required="true"
                        placeholder="Your Password"
                        style={{ borderRadius: "10px" }}
                      />
                    </FormControl>

                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          lg: "flex",
                        },
                        alignItems: "center",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<CustomCheckbox />}
                          label="Remeber this Device"
                          sx={{
                            mb: 0,
                            mt: 2,
                          }}
                        />
                      </FormGroup>
                    </Box>
                    {/* <Button
                      className="signUp-button"
                      onClick={loginUser}
                      style={{
                        width: "100%",
                        marginTop: "30px",
                        background: "#0760EA",
                        height: "3rem",
                        borderRadius: "10px",
                      }}
                      variant="contained"
                    >
                      {loading ? (
                        <>
                          <CircleLoader
                            loading={loading}
                            cssOverride={override}
                            size={40}
                            color="white"
                          />
                        </>
                      ) : (
                        "sign In"
                      )}
                    </Button> */}
                    <Button
          variant="contained"
          sx={{width: "100%",
          marginTop: "30px",
          background: "#0760EA",
          height: "3rem",
          borderRadius: "10px",}}
          disabled={loading}
          onClick={loginUser}
        >
          {loading ? (
          <CircularProgress
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : (<>sign In</>) }
        </Button>
                  </form>
                </FormProvider>
                <Typography
                  color="#16182B"
                  variant="h3"
                  fontWeight="300"
                  fontSize="15px"
                  textAlign="center"
                  sx={{
                    mt: 4,
                    mb: 2,
                  }}
                >
                  <Link
                    to="/auth/reset-password"
                    style={{ color: "#0760ea", textDecoration: "none" }}
                  >
                    Forget Password?
                  </Link>
                </Typography>
                <Typography
                  color="#16182B"
                  variant="h3"
                  fontWeight="300"
                  fontSize="15px"
                  textAlign="center"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                >
                  Want to register with us{" "}
                  <Link
                    to="/auth/register"
                    style={{ color: "#0760ea", textDecoration: "none" }}
                  >
                    Create Account
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "25px",
            }}
          >
            <div>
              <Link to="/">
                <h1 style={styles.privacyPolicy}>Privacy Policy</h1>
              </Link>
            </div>
            <div>
              <h1 style={styles.copyRight}>Copyright 2023</h1>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          style={styles.paperContainer}
          sx={{
            background: (theme) =>
              `${theme.palette.mode === "dark" ? "#1c1f25" : "#ffffff"}`,
          }}
        >
          <Box sx={{ mt: 8 }}>
            <CustomSlider {...settings}>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
            </CustomSlider>
            <br />
            <img src={Child1} style={styles.img} alt="child img" />
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Login;

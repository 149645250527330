import React from 'react';
import { Box, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    bgcolor: '#f5f5f5',
  },
  heading: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
    fontFamily: 'DM sans',
  },
  icon: {
    marginTop: '2rem',
    padding: '20px',
    height: '14rem',
    width: '14rem',
    color: '#0760EA',
  },
};

function EmailVerification() {
  return (
    <Box sx={styles.container}>
      <LoginIcon
      sx={styles.icon} />
      <Typography
        color="#0760EA"
        variant="h1"
        fontWeight="bold"
        fontSize="40px"
        style={{ textAlign: 'center' }}
        sx={{ mr: 1 }}
      >
        Email Verified
      </Typography>

      <Typography
        color="gray"
        variant="h3"
        fontWeight="500"
        fontSize="25px"
        style={{ textAlign: 'center' }}
        sx={{ mt: 1 }}
      >
        Your email has been successfully verified!
      </Typography>
    </Box>
  );
}

export default EmailVerification;

import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import CustomButton from "../../../components/forms/custom-elements/customButton";

export default function PreviewPost({ data }) {
  const styles = {
    container: { marginTop: "30px", padding: "16px" },
    paper: {
      padding: "24px",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    title: {
      color: "#0760EA",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "12px",
      textAlign: "center",
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "8px",
      marginBottom: "20px",
    },
    subhead: {
      color: "#0760EA",
      fontSize: "1.2rem",
      fontWeight: "bold",
      marginBottom: "12px",
    },
    body: {
      color: "#444",
      fontSize: "1rem",
      lineHeight: "1.6",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    button: {
      background: "#0760EA",
      color: "#fff",
      fontWeight: "bold",
      textTransform: "none",
      marginRight: "10px",
      "&:hover": {
        background: "#075FE8",
      },
    },
  };

  return (
    <Container sx={styles.container}>
      <Paper elevation={3} sx={styles.paper}>
        <Grid container spacing={2} sx={{display:'flex', justifyContent:'right'}}>
          <Grid item xs={3} sx={styles.buttonContainer}>
            <CustomButton text="Regenerate" style={styles.button} />
            </Grid>
            <Grid item xs={3} sx={styles.buttonContainer}>
            <CustomButton text="Upload" style={styles.button} />
            </Grid>
            <Grid item xs={3} sx={styles.buttonContainer}>
            <CustomButton text="Copy HTML" style={styles.button} />
            </Grid>
          </Grid>
          <br />
            <Typography variant="h1" sx={styles.title}>
              {data.title}
            </Typography>
          
          
            <img src={data.image} alt={data.title} style={styles.image} />
          
          
            <Typography variant="h2" sx={styles.subhead}>
              {data.subhead}
            </Typography>
          
          
            <Typography variant="body1" sx={styles.body}>
              {data.body}
            </Typography>
          
        
      </Paper>
    </Container>
  );
}

import { GET_INDUSTRIES } from "../constants";

const INIT_STATE = {
  industries: [],
};

const IndustryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INDUSTRIES:
      return {
        ...state,
        industries: [...action.industries],
      };

    default:
      return state;
  }
};

export default IndustryReducer;

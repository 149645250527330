import { Box, Typography } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import React from "react";

const CardStyles = {
  backgroundColor: "transparent", // Set background color to transparent
  color: "#075FE8", // Text color
  padding: "16px",
  borderRadius: "8px",
  border: "2px solid #075FE8", // Add a border
  cursor: "pointer",
  transition: "background-color 0.3s, border-color 0.3s", // Include border-color transition
};

const Card = ({ title, description }) => {
  const handleCardClick = () => {
    // Handle navigation logic here
    console.log(`Navigating to ${title}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
        },
        marginTop: "50px",
        borderTop: "4px solid rgba(7, 96, 234, 1)",
        borderRadius: "12px",
      }}
    >
      <Box style={CardStyles} onClick={handleCardClick}>
        <h3>{title}</h3>
        <p>{description}</p>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography component={'h1'}>
                {">"}
            </Typography>
            </Box>
      </Box>
    </Box>
  );
};

export default Card;

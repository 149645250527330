import { useRoutes } from "react-router-dom";
import route from "./route";
import { checkAutoLogin } from "./app/auth/auth.service";
import { useSelector } from "react-redux";

function App() {
  const auth = useSelector((state) => state.auth);
  console.log(auth.onboard);
  const routing = useRoutes(route(checkAutoLogin(), auth.onboard));
  return routing;
}

export default App;

import { Box, Grid, } from "@mui/material";
import React from "react";
import CustomTextField from "../../../components/forms/custom-elements/customTextField";
import { ReactComponent as CrossIcon } from '../../../assets/images/icon/cross.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icon/plus.svg';

function AddCompetitorsInput() {
  const styles = {
    textField: {
      fontSize: "16px",
      borderRadius: 12,
      borderColor: "#E2E8F0",
      display: "flex",
      justifyContent: "space-between",
    },
    buttonText: {
      fontWeight: 700,
      fontSize: "26px",
      lineHeight: "26px",
      
    },
  };
  return (
    <Grid
      sx={{ display: "flex", justifyContent: "space-evenly", alignItems:'center' }}
      container
      gap={0}
    >
      <Grid item xs={5}>
        <CustomTextField
          variant="outlined"
          sx={styles.textField}
          multiline
          placeholder="Enter your competitor name"
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextField
          variant="outlined"
          sx={styles.textField}
          multiline
          placeholder="website url of competitor"
        />
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ borderRadius:'12px',width: "45px", height:'45px', bgcolor:'#EF180314', justifyContent:'center', alignItems:'center', display:'flex' }}>
        <CrossIcon />
        </Box>
      </Grid>
      <Grid item xs={1}>
      <Box sx={{ borderRadius:'12px',width: "45px", height:'45px', bgcolor:'#EF180314', justifyContent:'center', alignItems:'center', display:'flex' }}>
<PlusIcon />
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddCompetitorsInput;

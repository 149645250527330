import { connect } from "react-redux";
import { register } from "../../../app/auth/auth.actions";
import Register from "./register";

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.loading,
    message: state.auth.error,
    showAlert: state.auth.showAlert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  register: (firstName, lastName, email, password, navigate) => {
    dispatch(register(firstName, lastName, email, password, navigate));
  },
});

const RegisterPage = connect(mapStateToProps, mapDispatchToProps)(Register);
export default RegisterPage;

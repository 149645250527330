import React from 'react';
import { Box, Paper, Typography, Chip } from '@mui/material';

const Post = ({ image, title, keywords, topic ,tone, type }) => {
  const postContainerStyle = {
    padding: '16px',
    marginBottom: '20px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const titleStyle = {
    color: '#0760EA',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '12px',
    textAlign: 'center',
  };

  const textLabelStyle = {
    color: '#0760EA',
    marginBottom: '8px',
  };

  const chipContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    marginBottom: '12px',
  };

  const imageStyle = {
    maxWidth: '100%',
    height: '100%',
    marginBottom: '12px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  };

  return (
    <Paper elevation={3} sx={postContainerStyle}>
      <img src={image} alt={title} style={imageStyle} />
      <Typography variant="h5" component="h2" gutterBottom style={titleStyle}>
        {title}
      </Typography>
      <Box sx={chipContainerStyle}>
        {keywords.map((keyword, index) => (
          <Chip key={index} label={keyword} color="primary" />
        ))}
      </Box>
      <Typography variant="body1" gutterBottom style={textLabelStyle}>
        Topic: <span style={{ fontStyle: 'italic' }}>{topic}</span>
      </Typography>
      <Typography variant="body1" gutterBottom style={textLabelStyle}>
        Tone: <span style={{ fontWeight: 'bold' }}>{tone}</span>
      </Typography>
      <Typography variant="body1" gutterBottom style={textLabelStyle}>
        Type: <span style={{ fontStyle: 'italic' }}>{type}</span>
      </Typography>
    </Paper>
  );
};

export default Post;

import { Box, Typography } from '@mui/material'
import React from 'react'

export default function SecurityText() {
  return (
    <Box
        sx={{ 
        padding: '16px 24px',
        borderRadius: '12px',
        border: '1px solid #0760EA',
        gap: '4px',
        background: 'linear-gradient(0deg, rgba(7, 96, 234, 0.04), rgba(7, 96, 234, 0.04))',
      }}>
        <Typography variant="caption" style={{ marginRight: "auto", color:'#0760EA' }}>
        Please create application password on Wordpress and enter in below password field. Username
should be your Wordpress username so that our system can publish articles directly to your
website.<br />
<b>NOTE:</b>  We store these information securely in encrypted form
        </Typography>
    </Box>

  )
}

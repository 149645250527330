import React from 'react';
import CustomTextField from '../../../components/forms/custom-elements/customTextField';

const styles = {
  root: {
    borderRadius: 12,
    borderColor: '#E2E8F0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 12,
  }
};

const BusinessInformation = () => {
  return (
    <CustomTextField
      variant="outlined"
      sx={styles.root}
      multiline
      rows={5}
      placeholder="Enter detailed information of your business"
    />
  );
};

export default BusinessInformation;

import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Header from "../dashboard/header";
import { ReactComponent as RankifyLogo } from "../../assets/rankify/header/logo.svg";

const drawerWidth = 268;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "30px",
  marginBottom: "30px",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "rgba(250, 250, 250, 1)",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      "&::-webkit-scrollbar": {
        width: "3px", // Customize the width of the scrollbar
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightGray", // Customize the color of the scrollbar thumb
        borderRadius: "4px", // Customize the border radius of the thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent", // Customize the color of the scrollbar track
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      "&::-webkit-scrollbar": {
        width: "3px", // Customize the width of the scrollbar
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightGray", // Customize the color of the scrollbar thumb
        borderRadius: "4px", // Customize the border radius of the thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent", // Customize the color of the scrollbar track
      },
    },
  }),
  backgroundColor: "rgba(255, 255, 255, 1)",
}));

export default function Sidebar(props) {
  const { children, menu, step, headerMenu, handleItem } = props;

  const [open, setOpen] = useState(true);
  const [workspace, setWorkspace] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          borderRight: "2px solid #E2E8F0",
          
        }}
      >
        <AppBar
          sx={{
            backgroundColor: "rgba(250, 250, 250, 1)",
            boxShadow: 0,
            zIndex: 1,
            paddingBottom: "20px",
            
          }}
          position="fixed"
          open={open}
        >
          <Toolbar sx={{ width: "100%", }}>
            <Header step={step} menu={headerMenu} />
          </Toolbar>
        </AppBar>
        <Drawer sx={{
          "& .MuiDrawer-paper":{
          overflow:'visible'
          }
        }} variant="permanent" open={open}>
          <DrawerHeader>
            <Box sx={{ mr: 15 }}>
              <RankifyLogo />
            </Box>
            {/* <Tooltip title="Workspace">
                            <IconButton sx={{ p: 0 }} onClick={() => setWorkspace(true)}>
                                <KeyboardArrowDownIcon sx={{ color: 'black', paddingTop: '20px', paddingLeft: '10px', cursor: 'pointer' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px', p: 3 }}
                            id="menu-appbar2"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={workspace}
                            onClose={() => setWorkspace(false)}
                        >
                            {['workspace 1', 'workspace 2'].map((setting) => (
                                <MenuItem key={setting}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu> */}
            <Box>
              {open ? (
                <IconButton
                  sx={{ position: "absolute", right: -40 }}
                  onClick={handleDrawerClose}
                >
                  {menu.icon.open}
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerOpen}>
                  {menu.icon.close}
                </IconButton>
              )}
            </Box>
          </DrawerHeader>
          {menu.members.map((member) => (
            <div key={member.title}>
              {open ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "rgba(22, 24, 43, 1)",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    paddingLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  {member.title}
                </Typography>
              ) : (
                " "
              )}
              <List
                sx={{
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    "&, & .MuiListItemIcon-root": {
                      color: "black",
                    },
                  },
                  "& .MuiListItemButton-root:hover": {
                    "&, & .MuiListItemIcon-root": {
                      color: "black",
                    },
                  },
                }}
              >
                {member.items.map((item) => (
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => handleItem(item.title)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "flex-start" : "center",
                        px: 2.5,
                        backgroundColor:
                          step === item.title ? "rgba(7, 96, 234, 0.8)" : "",
                        borderRadius: step === item.title ? "6px" : "",
                        color: step === item.title ? "white" : "black",
                        marginLeft: step === item.title ? "10px" : "",
                        marginRight: step === item.title ? "10px" : "",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                          color: step === item.title ? "white" : "",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
                {/* <ListItem key={"Nudge To Upgrade Plan or Free Trial Ends"} disablePadding sx={{ display: 'block' }} onClick={() => handleItem("Nudge To Upgrade Plan or Free Trial Ends")}>
                                        <ListItemButton
                                            sx={{
                                                width: '100%',
                                                paddingTop: '30px',
                                                paddingBottom: '30px',
                                                marginTop: '80px',
                                                marginLeft: '-5px',
                                                border: '1px solid black'
                                            }}
                                        >
                                            {open ? '' : <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 1 : 'auto',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {'N'}
                                            </ListItemIcon>}
                                            <ListItemText primary={"Nudge To Upgrade Plan or Free Trial Ends\n"} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem> */}
              </List>
            </div>
          ))}
        </Drawer>
      </Box>
      <Box component="main" sx={{ marginTop: 6, flexGrow: 1 }}>
        <Container>{children}</Container>
      </Box>
    </Box>
  );
}

import { Box, ButtonBase, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../../components/forms/custom-elements/customTextField";
import { ReactComponent as CrossIcon } from "../../../assets/images/icon/cross.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plus.svg";
import { useFormContext } from "react-hook-form";

function AddService() {
  const styles = {
    textField: {
      fontSize: "16px",
      borderRadius: 12,
      borderColor: "#E2E8F0",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiOutlinedInput-root": {
        background: "#fff",
  
        height: "51px",
        top: "2px",
        left: "6px",
        borderRadius: "12px",
        gap: "12px",
        bgcolor: "#fff",
        borderBottom: 0,
      },
      "& .Mui-focused": {
        bgcolor: "#fff",
      },
   },
    buttonText: {
      fontWeight: 700,
      fontSize: "26px",
      lineHeight: "26px",
    },
  };
  const formMethods = useFormContext();
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [serviceUrl, setServiceUrl] = useState("");

  useEffect(()=>{
    formMethods.reset({
      ...formMethods.getValues(),
      services: services
    })
    console.log('updated form method with services: ', formMethods.getValues())
  },[services])
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      container
      gap={0}
      spacing={1}
    >
      <Grid item xs={5}>
        <CustomTextField
          variant="outlined"
          sx={styles.textField}
          multiline
          placeholder="Enter your service/product"
          value={serviceName}
          onChange={({ target }) => {
            setServiceName(target.value);
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <CustomTextField
          variant="outlined"
          sx={styles.textField}
          multiline
          placeholder="URL of service/product"
          value={serviceUrl}
          onChange={({ target }) => {
            setServiceUrl(target.value);
          }}
        />
      </Grid>
      <Grid item spacing={2} sx={{display:'flex', justifyContent:'center'}} container xs={2}>
        <Grid item>
        <ButtonBase
          sx={{
            borderRadius: 2,
          }}
          onClick={() => {
            setServiceUrl("");
            setServiceName("");
          }}
        >
          <CrossIcon />
        </ButtonBase>
        </Grid>
        <Grid item>
        <ButtonBase
          sx={{
            borderRadius: 2,
          }}
          onClick={() => {
            setServices((prevState) => [
              ...prevState,
              { serviceName, serviceUrl },
            ]);
          }}
        >
          <PlusIcon
          />
        </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddService;

export const domains = [
  "0-180.com",
  "0-30-24.com",
  "0-420.com",
  "0-900.com",
  "0-aa.com",
  "0-mail.com",
  "0-z.xyz",
  "0.mail.mujur.id",
  "0.pbot.tk",
  "00.pe",
  "000000pay.com",
  "00043015.com",
  "000476.com",
  "000521.xyz",
  "00082aa.com",
  "00082cc.com",
  "00082dd.com",
  "00082ff.com",
  "00082ii.com",
  "00082mm.com",
  "00082rr.com",
  "00082ss.com",
  "00082uu.com",
  "00082xx.com",
  "00082zz.com",
  "000865b.com",
  "000865e.com",
  "000865g.com",
  "000865j.com",
  "00093015.com",
  "0009827.com",
  "0009837.com",
  "000av.app",
  "000br88.com",
  "000xxoo.com",
  "0010.monster",
  "0018k7.com",
  "001913.com",
  "0019k7.com",
  "001xs.net",
  "001xs.org",
  "001xs.xyz",
  "002.city",
  "002288211.com",
  "002r.com",
  "002t.com",
  "0031casino.com",
  "003271.com",
  "003388211.com",
  "0039.cf",
  "0039.ga",
  "0039.gq",
  "0039.ml",
  "003919.com",
  "003j.com",
  "004697.com",
  "004k.com",
  "004r.com",
  "005005.xyz",
  "005588211.com",
  "0058.ru",
  "005f4.xyz",
  "006j.com",
  "006o.com",
  "006z.com",
  "007.surf",
  "007946.com",
  "007948.com",
  "007dotcom.com",
  "007security.com",
  "008106.com",
  "0083015.com",
  "008g8662shjel9p.xyz",
  "0094445.com",
  "009988211.com",
  "009qs.com",
  "00b2bcr51qv59xst2.cf",
  "00b2bcr51qv59xst2.ga",
  "00b2bcr51qv59xst2.gq",
  "00b2bcr51qv59xst2.ml",
  "00b2bcr51qv59xst2.tk",
  "00daipai.com",
  "00g0.com",
  "00reviews.com",
  "00sh.cf",
  "00xht.com",
  "01-lund.ru",
  "0100110tomachine.com",
  "01011099.com",
  "0101888dns.com",
  "0104445.com",
  "01080.ru",
  "010880.com",
  "01092019.ru",
  "010pc28.com",
  "010xfhs.com",
  "01106.monster",
  "0111vns.com",
  "01122200.com",
  "01122233.com",
  "ezztt.com",
  "yopmail.com",
  "chodyi.com",
  "code-gmail.com",
];

export default domains;

import { AUTH_ACTIONS } from "./actions.constants";

const INITIAL_STATE = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  isActive: false,
  isLoggedIn: false,
  onboard: false,
  loading: false,
  appLoading: false,
  isVerified: false,
  error: "",
  success: false,
  role: "",
  solutionRight: false,
  tempToken: "",
  showAlert: false,
};
export const auth = (state = INITIAL_STATE, action) => {
  console.log(action.type);
  switch (action.type) {
    case AUTH_ACTIONS.RESET_STATUS:
      return {
        ...state,
        loading: false,
        success: false,
        error: "",
        tempToken: "",
        showAlert: false,
      };
    case AUTH_ACTIONS.FETCHED_LOGIN:
      const {
        id,
        firstName,
        onboard,
        lastName,
        email,
        avatar,
        isActive,
        isVerified,
      } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        success: true,
        loading: false,
        onboard: onboard,
        firstName,
        lastName,
        email,
        avatar,
        isActive,
        isVerified,
      };
    case AUTH_ACTIONS.FETCHING_LOGIN:
      return { ...state, loading: true, showAlert: false };
    case AUTH_ACTIONS.FETCHED_USER:
      return {
        ...state,
        showAlert: false,
        userId: action.payload.id,
        appLoading: false,
        success: true,
        error: "",
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        avatar: action.payload.avatar,
        isActive: action.payload.isActive,
        isVerified: action.payload.isVerified,
        isLoggedIn: true,
        onboard: action.payload.onboard,
        role: action.payload.Role,
        solutionRight: action.payload.solutionRight,
      };
    case AUTH_ACTIONS.FETCHING_USER:
      return { ...state, appLoading: true, success: false, error: "" };
    case AUTH_ACTIONS.USER_FAILED:
      return {
        ...state,
        error: action.payload,
        success: false,
        appLoading: false,
      };
    case AUTH_ACTIONS.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        showAlert: true,
        success: false,
        loading: false,
      };
    case AUTH_ACTIONS.REGISTERING_USER:
      return { ...state, loading: true, showAlert: false };
    case AUTH_ACTIONS.USER_REGISTERED:
      return { ...state, loading: false, success: true };
    case AUTH_ACTIONS.REGISTRATION_FAILED:
      return {
        ...state,
        loading: false,
        showAlert: true,
        error: action.payload,
        success: false,
      };
    case AUTH_ACTIONS.FORGOT_PASSWORD_INITIATED:
      return {
        ...state,
        loading: true,
        success: false,
        error: "",
        showAlert: false,
      };
    case AUTH_ACTIONS.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case AUTH_ACTIONS.FORGOT_PASSWORD_DONE:
      return { ...state, loading: false, success: true };
    case AUTH_ACTIONS.RESET_PASSWORD_STARTED:
      return { ...state, loading: true, success: false, error: "" };
    case AUTH_ACTIONS.RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        showAlert: true,
        error: action.payload,
      };
    case AUTH_ACTIONS.RESET_PASSWORD_DONE:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        tempToken: "",
      };
    case AUTH_ACTIONS.SET_TEMP_TOKEN:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        tempToken: action.payload,
      };
    case AUTH_ACTIONS.VERIFYING_EMAIL:
      return {
        ...state,
        loading: true,
        success: false,
        error: "",
        tempToken: "",
      };
    case AUTH_ACTIONS.VERIFIED:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        tempToken: "",
      };
    case AUTH_ACTIONS.VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        tempToken: "",
      };
    case AUTH_ACTIONS.UPDATING_PROFILE:
      return {
        ...state,
        loading: true,
        success: false,
        error: "",
        tempToken: "",
      };
    case AUTH_ACTIONS.UPDATING_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        tempToken: "",
      };
    case AUTH_ACTIONS.UPDATING_DONE:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        tempToken: "",
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      };
    case AUTH_ACTIONS.ONBOARDING_USER:
      return { ...state, loading: true, showAlert: false };
    case AUTH_ACTIONS.ONBOARDED_USER:
      return { ...state, loading: false, onboard: true, showAlert: true };
    case AUTH_ACTIONS.ONBOARDING_USER_FAILED:
      return {
        ...state,
        loading: false,
        onboard: false,
        error: "something went wrong",
      };
    default:
      // console.log("default called");
      return state;
  }
};

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { FormProvider, useForm } from "react-hook-form";

import { userOnBoard } from "../../redux/user/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { onboarding, fetchUser } from "../../app/auth/auth.actions";

const onBoardingContextDefaultValues = {};

const OnBoardingContext = createContext(onBoardingContextDefaultValues);

export const useOnBoardingContext = () => useContext(OnBoardingContext);

const OnBoardingContextProvider = ({
  fetchUser,
  onboarding,
  children,
  onboard,
}) => {
  const [step, setStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState({});

  const navigate = useNavigate();
  const formMethods = useForm();

  useEffect(() => {
    fetchUser();
    if (onboard) {
      navigate("/dashboard/mainDashboard");
    }
  }, [onboard]);
  const onNext = useCallback(() => {
    let isError = false;

    if (step === 0) {
      setSelectedPlan({
        id: formMethods.getValues().planId,
        name: formMethods.getValues().planName,
        price: formMethods.getValues().planPrice,
      });
      console.log("selectedPlan", selectedPlan);
    }

    if (step === 1) {
      if (!formMethods.getValues("company.name")) {
        formMethods.setError("company.name", {
          message: "Organization Name is required",
        });
        isError = true;
      }
    }

    if (step === 2) {
      if (
        !formMethods.getValues("workspace.name") ||
        formMethods.getValues("workspace.name")?.length === 0
      ) {
        formMethods.setError("workspace.name", {
          message: "Workspace name required",
        });
        isError = true;
      }

      if (step === 3) {
        if (
          !formMethods.getValues("keywords") ||
          formMethods.getValues("keywords")?.length === 0
        ) {
          formMethods.setError("keywords", {
            message: "Keywords are required",
          });
          isError = true;
        }
        if (!formMethods.getValues("niche")) {
          formMethods.setError("niche", { message: "Niche is required" });
          isError = true;
        }
        if (!formMethods.getValues("summaryOfBusiness")) {
          formMethods.setError("summaryOfBusiness", {
            message: "Summary Of Business is required",
          });
          isError = true;
        }
        if (!formMethods.getValues("industry")) {
          formMethods.setError("industry", { message: "Industry is required" });
          isError = true;
        }
      }

      if (!formMethods.getValues("workspace.siteurl")) {
        formMethods.setError("workspace.siteurl", {
          message: "Url is required",
        });
        isError = true;
      }
    }
    // if (step === 5) {
    //   if (
    //     !formMethods.getValues("wordpress.email") ||
    //     formMethods.getValues("wordpress.email")?.length === 0
    //   ) {
    //     formMethods.setError("wordpress.email", {
    //       message: "email required",
    //     });
    //     isError = true;
    //   }

    //   if (!formMethods.getValues("wordpress.password")) {
    //     formMethods.setError("wordpress.password", {
    //       message: "password is required",
    //     });
    //     isError = true;
    //   }
    // }

    if (isError) return;

    setStep((prev) => prev + 1);
  }, [step]);

  const onPrev = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);

  const onSkip = useCallback(() => {
    setStep((prev) => prev + 1);
  }, [step]);

  const onFinish = useCallback(() => {
    console.log(formMethods.getValues());
    onboarding(formMethods.getValues(), navigate);

    // dispatch(userOnBoard(formMethods.getValues()));
  }, []);

  const values = useMemo(
    () => ({
      // States
      step,
      selectedPlan,
      onNext,
      onPrev,
      onFinish,
      onSkip,
    }),
    [step, selectedPlan, onNext, onPrev, onFinish, onSkip]
  );

  return (
    <OnBoardingContext.Provider value={values}>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </OnBoardingContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
    loading: state.auth.loading,
    onboard: state.auth.onboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onboarding: (payload) => {
    dispatch(onboarding(payload));
  },
  fetchUser: () => {
    dispatch(fetchUser());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnBoardingContextProvider);

import axios from 'axios'
import { GET_INDUSTRIES } from '../constants'
import { getBaseApi } from '../../config/Enviroment'

export const getIndustries = () => {
    return (dispatch) => {
    axios.get(`${getBaseApi()}blog/get-all-industry`).then((res) => {
        dispatch({
          type: GET_INDUSTRIES,
          industries: res.data,
        });
      });
    }
}
import { ReactComponent as RankifyLogo } from "../../assets/rankify/header/logo.svg";
import { ReactComponent as UserLogo } from "../../assets/rankify/header/user.svg";

import Box from "@mui/material/Box";

function Header({ user }) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '20px',
            paddingBottom: '20px'
        }}>
            <RankifyLogo />
            <div style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center'
            }}>
                <p style={{ marginRight: '20px' }}>Hi, {user}</p>
                <Box
                    sx={{
                        border: "1px solid #E2E8F0",
                        borderRadius: '555px',
                        padding: '16px',
                    }}
                >
                    <UserLogo />
                </Box>
            </div>
        </div>
    )
}

export default Header;
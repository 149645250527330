import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StepChanger from "../../steps/StepChanger";
import SelectIndustry from "../../../../components/onboarding/configure_workspace/SelectIndustry";
import Tags from "../../../../components/onboarding/configure_workspace/Tags";
import BusinessInformation from "../../../../components/onboarding/configure_workspace/BusinessInformation";
import KeywordSuggestion from "../../../../components/onboarding/configure_workspace/KeywordSuggestion";
import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import AddService from "../../../../components/onboarding/configure_workspace/AddService";
import { useState } from "react";

export default function StepEight() {
  const [keywords, setKeywords] = useState([])
  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3>Configure Workspace</h3>
        <SelectIndustry />
        <br />
        <Tags />
        <br />
        <BusinessInformation />
        <br />
        <CustomTextField
        sx={{  display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',}}
        value={keywords.join(", ")}
        placeholder="Enter your keywords"
        onChange={({target})=>{
        setKeywords([...keywords, target.value])
        }}
        />
        <br />
        <KeywordSuggestion
        setKeywords={setKeywords}
        />
        <br />
        <AddService />
        <br />
        <StepChanger />
      </Box>
    </Grid>
  );
}

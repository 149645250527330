import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import "slick-carousel/slick/slick.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomCheckbox from "../../../components/forms/custom-elements/customCheckbox";
import CustomSlider from "../../../components/forms/custom-elements/mySlider";
import CustomFormLabel from "../../../components/forms/custom-elements/customFormLabel";
import PageContainer from "../../../components/container/PageContainer";
import { ReactComponent as Logo } from '../../../assets/images/icon/rankify.svg';
import PersonIcon from "../../../assets/images/icon/person.png";
import EmailIcon from "../../../assets/images/icon/email.png";
import PasswordIcon from "../../../assets/images/icon/password.png";
import Background from "../../../assets/images/icon/Group 48096129.png";
import Child1 from "../../../assets/images/icon/image 2.png";
import { validateEmail } from "../../../utils/validator/validator";
import { blue } from "@mui/material/colors";

// styles
const styles = {
  paperContainer: {
    backgroundImage: `url(${Background})`,
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  heading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  heading2: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "0px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  paragraph: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "40px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  img: {
    textAlign: "center",
    width: "70%",
    height: "70%",
    display: "block",
    margin: "auto",
    marginTop: "30px",
  },
  privacyPolicy: {
    fontSize: "16px",
    color: "#16182B",
  },
  copyRight: {
    fontSize: "16px",
    color: "#718096",
    textDecoration: "none",
  },
};

const Register = ({ isLoading, register, showAlert, message }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const firstName = name;
  const lastName = name;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));
  };

  const registerUser = (event) => {
    event.preventDefault();
    if (email && password.length > 7 && name) {
      if (isValid) {
        register(firstName, lastName, email, password, navigate);
      } else {
        setShowError(true);
        setShowErrorMessage("Invalid/Disposable email not allowed");
      }

      // navigate('/auth/verifyemail');
    } else {
      setShowError(true);

      setShowErrorMessage("Please fill the form correctly");
    }
  };

  return (
    <PageContainer
      title="Login - Edaly App"
      description="Edaly Application Login Page"
    >
      <Grid
        container
        spacing={0}
        sx={{
          height: "100vh",
          background: "white",
        }}
      >
        <Grid item xs={12} sm={12} lg={6} alignSelf="center">
          <Grid
            spacing={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            item
            xs={12}
            lg={9}
            xl={8}
          >
            <Box
              sx={{
                p: 4,
              }}
            >
              <Stack
                style={
                  showError
                    ? { display: "block", marginBottom: "30px" }
                    : { display: "none" }
                }
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert
                  variant="filled"
                  severity="error"
                  style={{ margin: "auto" }}
                >
                  {showErrorMessage}
                </Alert>
              </Stack>
              <Stack
                style={
                  showAlert
                    ? { display: "block", marginBottom: "30px" }
                    : { display: "none" }
                }
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert
                  severity="warning"
                  style={{ background: "red", color: "white", margin: "auto" }}
                >
                  {message}
                </Alert>
              </Stack>
              
              <Logo style={{ height: '44px', width: '134px' }} alt="logo" />

              <Typography
                color="black"
                variant="h1"
                fontWeight="600"
                fontSize="35px"
                sx={{
                  mr: 1,
                  mt: 2,
                }}
              >
                hi, come join us!
              </Typography>

              <Typography
                color="#718096"
                variant="h3"
                fontWeight="400"
                fontSize="14px"
                sx={{
                  mt: 2,
                }}
              >
                Sign in to get most out of Rankify Ai
              </Typography>

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <form>
                  <CustomFormLabel
                    style={{
                      fontSize: "14px",
                      color: "#16182B",
                      fontWeight: "600",
                    }}
                    htmlFor="name"
                  >
                    Full Name
                  </CustomFormLabel>
                  <FormControl fullWidth variant="filled">
                    <InputLabel
                      style={{ display: "none" }}
                      htmlFor="outlined-adornment-amount"
                    >
                      sd
                    </InputLabel>
                    <OutlinedInput
                      id="name"
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginTop: "-2px" }}
                        >
                          <img src={PersonIcon} alt="icon" />{" "}
                        </InputAdornment>
                      }
                      onChange={(event) => setName(event.target.value)}
                      value={name}
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="Full Name"
                      style={{ borderRadius: "10px" }}
                    />
                  </FormControl>
                  <br />
                  <CustomFormLabel
                    style={{
                      fontSize: "14px",
                      color: "#16182B",
                      fontWeight: "600",
                    }}
                    htmlFor="email"
                  >
                    Email
                  </CustomFormLabel>

                  <FormControl fullWidth variant="filled">
                    <InputLabel
                      style={{ display: "none" }}
                      htmlFor="outlined-adornment-amount"
                    >
                      sd
                    </InputLabel>
                    <OutlinedInput
                      id="email"
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginTop: "-2px" }}
                        >
                          <img src={EmailIcon} alt="icon" />{" "}
                        </InputAdornment>
                      }
                      onChange={handleInputChange}
                      value={email}
                      variant="outlined"
                      fullWidth
                      required
                      type="email"
                      placeholder="email address"
                      style={{ borderRadius: "10px" }}
                    />
                  </FormControl>
                  <br />

                  <CustomFormLabel
                    style={{
                      fontSize: "14px",
                      color: "#16182B",
                      fontWeight: "600",
                    }}
                    htmlFor="password"
                  >
                    Password{" "}
                    <span style={{ fontSize: "10px" }}>
                      (Should be atleast 8 character)
                    </span>
                  </CustomFormLabel>

                  <FormControl fullWidth variant="filled">
                    <InputLabel
                      style={{ display: "none" }}
                      htmlFor="outlined-adornment-amount"
                    >
                      sd
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginTop: "-2px" }}
                        >
                          <img src={PasswordIcon} alt="icon" />{" "}
                        </InputAdornment>
                      }
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                      variant="outlined"
                      fullWidth
                      required="true"
                      placeholder="Min character 8"
                      style={{ borderRadius: "10px" }}
                    />
                  </FormControl>

                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        lg: "flex",
                      },
                      alignItems: "center",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<CustomCheckbox />}
                        label="Remember this Device"
                        sx={{
                          mb: 0,
                          mt: 1,
                        }}
                      />
                    </FormGroup>
                  </Box>
                  <Button
  type="submit"
  className="signUp-button"
  onClick={registerUser}
  style={{
    width: "100%",
    marginTop: "30px",
    background: "#0760EA",
    height: "3rem",
    borderRadius: "10px",
  }}
  variant="contained"
>
  {isLoading ? (
    <CircularProgress
      size={24}
      sx={{
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
      }}
    />
  ) : (
    "Sign Up"
  )}
</Button>

                </form>
                <Typography
                  color="#16182B"
                  variant="h3"
                  fontWeight="300"
                  fontSize="15px"
                  textAlign="center"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                >
                  Already have an account?{" "}
                  <Link to="/auth/login" style={{ color: "blue" }}>
                    Sign In
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "-20px",
            }}
          >
            <div>
              <Link to="/">
                <h1 style={styles.privacyPolicy}>Privacy Policy</h1>
              </Link>
            </div>
            <div>
              <h1 style={styles.copyRight}>Copyright 2023</h1>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          style={styles.paperContainer}
          sx={{
            background: (theme) =>
              `${theme.palette.mode === "dark" ? "#1c1f25" : "#ffffff"}`,
          }}
        >
          <Box sx={{ mt: 8 }}>
            <CustomSlider {...settings}>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
            </CustomSlider>
            <br />
            <img src={Child1} style={styles.img} alt="child img" />
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Register;

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepIcon from "./icon";

export default function VerticalLinearStepper({ steps, activeStep }) {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "black", // circle color (COMPLETED)
                fontWeight: "bold",
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                color: "black", // Just text label (COMPLETED)
                fontWeight: "bold",
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "rgba(7, 96, 234, 1)", // circle color (ACTIVE)
                fontWeight: "bold",
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                color: "rgba(7, 96, 234, 1)", // Just text label (ACTIVE)
                fontWeight: "bold",
              },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "rgba(7, 96, 234, 1)", // circle's number (ACTIVE)
                fontWeight: "bold",
              },
            }}
          >
            <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

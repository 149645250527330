import axios from "axios";
import { UPDATE_USER_SETTINGS } from "../constants";
import { getBaseApi } from "../../config/Enviroment";

export const updateUserSettings = (data) => {
  return (dispatch) => {
    axios.patch(`${getBaseApi()}user/update-profile`, data).then((res) => {
      dispatch({
        type: UPDATE_USER_SETTINGS,
        user: res.data
        });
      });
  };
};

export const userOnBoard = (data) => {
  return (dispatch) => {
    console.log(dispatch);
    axios
      .post("https://rankify-be.onrender.com/api/User/onbaord", data)
      .then((res) => {
        console.log(res);
        return {};
      });
  };
};

export const userOnBoardPost = (data) => {
  return (dispatch) => {
    axios.post(`${getBaseApi()}user/onBoard`, data).then((res) => {
      return {};
    });
  };
};

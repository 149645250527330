import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StepChanger from "../StepChanger";
import { Card, CardContent, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useLayoutEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../../../redux/plan/action";
import { useFormContext } from "react-hook-form";
import YearlySwitch from "../../../../components/onboarding/selected_plan/YearlySwitch";
import PlanCard from "../../../../components/onboarding/selected_plan/PlanCard";

const styles = {
  yearly__switch__container: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
};

function SelectPlan(props) {
  const formMethods = useFormContext();

  const dispatch = useDispatch();
  const plans = useSelector((state) => state.PlanReducer.plans);

  useLayoutEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (plans.length > 0) {
      if (!formMethods.getValues("planId")) {
        formMethods.reset(
          {
            ...formMethods.getValues(),
            planId: plans[0].id,
            planPrice: plans[0].price,
            planName: plans[0].planName
          },
          { keepErrors: true }
        );
      }
      
    }
  }, [plans]);

  const selectedPlan = formMethods.watch("planId");

  function onPlanChange(id) {
    formMethods.reset(
      {
        ...formMethods.getValues(),
        planId: id,
      },
      { keepErrors: true }
    );
  }

  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h3>Select a pricing plan</h3>
          </Grid>
          <Grid item sx={styles.yearly__switch__container} xs={4}>
            <YearlySwitch />
          </Grid>
        </Grid>

        {plans?.map((plan, index) => (
          <PlanCard
          key={index}
          plan={plan}
          onPlanChange={onPlanChange}
          selectedPlan={selectedPlan}
          />
        ))}

        <StepChanger />
      </Box>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    plans: state.plans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);

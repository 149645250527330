import React from "react";


import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteIcon from '@mui/icons-material/Delete';


import Alert from "../../../components/alert";
import CoverPhotoUploader from "../../../components/dashboard/settings/coverPhotoUploader";
import ProfilePhotoUploader from "../../../components/dashboard/settings/profilePictureUploader";

export default function Settings(props) {

    const { setItem } = props;

    const Alerts = [
        {
            'category': 'General',
            'element': [
                {
                    type: 'info',
                    data: 'Personal Settings',
                    icon: <ColorLensIcon />,
                    action: <Button color="inherit" size="small" onClick={() => setItem('Personal Settings')}><NavigateNextIcon /></Button>
                },
                {
                    type: 'info',
                    data: 'Billing/Invoice Settings',
                    icon: <ReceiptIcon />,
                    action: <Button color="inherit" size="small"><NavigateNextIcon /></Button>
                }
            ]
        },
        {
            category: 'Others',
            element: [
                {
                    type: 'error',
                    data: 'Delete Account',
                    icon: <DeleteIcon />,
                    action: <Button color="inherit" size="small"><NavigateNextIcon /></Button>
                }]
        }
    ]

    return (
        <Container sx={{ marginTop: '30px' }}>
            <Paper elevation={3}>
                <div>
                    <CoverPhotoUploader />
                </div>
                <div style={{ padding: '20px', paddingBottom: '10px' }}>
                    <ProfilePhotoUploader icon={true} id={true} />
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    {Alerts.map((alert, index) => (
                        <div key={index}>
                            <Typography
                                sx={{
                                    paddingTop: '20px',
                                    paddingBottom: '10px',
                                    textTransform: 'uppercase',
                                    fontWeight: 900,
                                    fontSize: '14px',
                                }}
                            >
                                {alert.category}
                            </Typography>
                            {alert.element.map((alertItem, subIndex) => (
                                <div style={{ paddingBottom: '10px' }}>
                                    <Alert
                                        key={subIndex}
                                        type={alertItem.type}
                                        data={alertItem.data}
                                        icon={alertItem.icon}
                                        action={alertItem.action}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </Paper>
        </Container>
    )
}
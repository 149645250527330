import React from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import CoverPhotoUploader from "../../../../components/dashboard/settings/coverPhotoUploader";
import ProfilePhotoUploader from "../../../../components/dashboard/settings/profilePictureUploader";

import { useDispatch } from "react-redux";
import { updateUserSettings } from "../../../../redux/user/action";
import { Controller, useFormContext } from "react-hook-form";

export default function ProfileSettings() {

    const formMethods = useFormContext();

    const dispatch = useDispatch();

    const submitForm = () => {
        dispatch(updateUserSettings(formMethods.getValues()));
    }

    return (
        <Container sx={{ marginTop: '30px' }}>
            <Paper elevation={3}>
                <div>
                    <CoverPhotoUploader />
                </div>
                <div style={{
                    display: 'flex',
                    textAlign: 'center',
                    flexWrap: 'nowrap',
                    flexDirection: 'row-reverse',
                    justifyContent: 'center'
                }}>
                    <ProfilePhotoUploader icon={false} id={false} />
                </div>

                <Grid container sx={{ p: 4 }} spacing={2}>

                    <Grid item xs={6}>
                        <Controller
                            control={formMethods.control}
                            name="firstName"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder="First Name"
                                    type="text"
                                    sx={{ paddingBottom: "20px" }}
                                    label="First Name"
                                    fullWidth
                                    error={!!formMethods.formState.errors.firstname}
                                    helperText={formMethods.formState.errors.firstname?.message}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            control={formMethods.control}
                            name="lastName"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder="Last Name"
                                    type="text"
                                    sx={{ paddingBottom: "20px" }}
                                    label="Last Name"
                                    fullWidth
                                    error={!!formMethods.formState.errors.firstname}
                                    helperText={formMethods.formState.errors.firstname?.message}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            control={formMethods.control}
                            name="email"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder="Email"
                                    type="text"
                                    sx={{ paddingBottom: "20px" }}
                                    label="Email"
                                    fullWidth
                                    error={!!formMethods.formState.errors.email}
                                    helperText={formMethods.formState.errors.email?.message}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            control={formMethods.control}
                            name="password"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder="Password *"
                                    type="password"
                                    sx={{ paddingBottom: "20px" }}
                                    label="Password *"
                                    fullWidth
                                    error={!!formMethods.formState.errors.password}
                                    helperText={formMethods.formState.errors.password?.message}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            control={formMethods.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder="Confirm Password *"
                                    type="password"
                                    sx={{ paddingBottom: "20px" }}
                                    label="Confirm Password *"
                                    fullWidth
                                    error={!!formMethods.formState.errors.confirmpassword}
                                    helperText={formMethods.formState.errors.confirmpassword?.message}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{ paddingLeft: '100px', paddingRight: '100px' }} onClick={submitForm}>Update Details</Button>
                    </Grid>

                </Grid>
            </Paper>
        </Container >
    )
}
export const AUTH_ACTIONS = {
  FETCHING_LOGIN: "FETCHING_LOGIN",
  FETCHED_LOGIN: "FETCHED_LOGIN",
  FETCHING_USER: "FETCHING_USER",
  FETCHED_USER: "FETCHED_USER",
  LOGIN_FAILED: "LOGIN_FAILED",
  USER_FAILED: "USER_FAILED",
  REGISTERING_USER: "REGISTERING_USER",
  USER_REGISTERED: "USER_REGISTERED",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",
  VERIFYING_EMAIL: "VERIFYING_EMAIL",
  VERIFICATION_FAILED: "VERIFICATION_FAILED",
  VERIFIED: "VERIFIED",
  FORGOT_PASSWORD_INITIATED: "FORGOT_PASSWORD_INITIATED",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  FORGOT_PASSWORD_DONE: "FORGOT_PASSWORD_DONE",
  RESET_PASSWORD_STARTED: "RESET_PASSWORD_VERIFICATION_STARTED",
  RESET_PASSWORD_DONE: "RESET_PASSWORD_VERIFICATION_DONE",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_VERIFICATION_FAILED",
  SET_TEMP_TOKEN: "SET_TEMP_TOKEN",
  RESET_STATUS: "RESET_STATUS",
  UPDATING_PROFILE: "UPDATING_PROFILE",
  UPDATING_FAILED: "UPDATING_FAILED",
  UPDATING_DONE: "UPDATING_DONE",
  ONBOARDING_USER: "ONBOARDING_USER",
  ONBOARDED_USER: "ONBOARDED_USER",
  ONBOARDING_USER_FAILED: "ONBOARDING_USER_FAILED",
};

export const AUTH_ENDPOINTS = {
  LOGIN: "user/login",
  USER: "user/me",
  REGISTER: "user/signup",
  VERIFY: "user/verify-email",
  FORGOT_PASSWORD: "user/forgot-password",
  VALIDATE: "user/validate-forgot-password",
  RESET_PASSWORD: "user/reset-password",
  PROFILE: "user/profile",
  ONBOARD: "User/onBoard",
};

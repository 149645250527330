import { connect } from "react-redux";
import { forgotPassword } from "../../../app/auth/auth.actions";
import ResetPassword from "./ResetPassword";

const mapStateToProps = (state) => {
  return {
    fetchedUser: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  Password: (email) => {
    dispatch(forgotPassword(email));
  },
});

const ResetPasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
export default ResetPasswordPage;

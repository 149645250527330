import React, { useState, useRef } from 'react';
import { Paper, Typography } from '@mui/material';

const FileUpload = (props) => {
    const { setFiles } = props;
    const [text, setText] = useState('Click to upload logo or drag and drop');
    const fileInputRef = useRef(null);
    const [isDragActive, setIsDragActive] = useState(true);

    const handleDragEnter = () => {
        setIsDragActive(true);
    };

    const handleDragLeave = () => {
        setIsDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragActive(false);
        // Handle dropped files here
        const files = e.dataTransfer.files;
        let text = 'Selected ';
        // eslint-disable-next-line array-callback-return
        Object.values(files).map((file, index) => {
            text += file.name + ' , ';
        })
        setText(text)
        setFiles(files);
    };

    const fileUpload = (e) => {
        fileInputRef.current.click();
    }

    return (
        <Paper
            elevation={2}
            onDragEnter={handleDragEnter}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 1,
                borderWidth: 2,
                borderRadius: 2,
                borderColor: 'rgba(7, 96, 234, 0.5)',
                borderStyle: 'solid',
                backgroundColor: 'rgba(7, 96, 234, 0.04)',
                outline: 'none',
                cursor: 'pointer'
            }}
            onClick={fileUpload}
        >
            <input
                type="file"
                inputProps={{ multiple: true }}
                onChange={(e) => {
                    e.preventDefault();
                    setIsDragActive(false);
                    // Handle selected files here
                    const files = e.target.files;
                    let text = 'Selected ';
                    // eslint-disable-next-line array-callback-return
                    Object.values(files).map((file) => {
                        text += file.name + ' , ';
                    })
                    setText(text);
                    setFiles(files);
                }}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#3C88FF" fill-opacity="0.16" />
                <g clip-path="url(#clip0_5_8886)">
                    <path d="M23.3333 23.3334L20 20M20 20L16.6666 23.3334M20 20V27.5M26.9916 25.325C27.8044 24.8819 28.4465 24.1808 28.8165 23.3322C29.1866 22.4837 29.2635 21.5361 29.0351 20.6389C28.8068 19.7418 28.2862 18.9463 27.5555 18.3779C26.8248 17.8095 25.9257 17.5006 25 17.5H23.95C23.6977 16.5244 23.2276 15.6186 22.5749 14.8509C21.9222 14.0831 21.104 13.4732 20.1817 13.0672C19.2594 12.6612 18.2571 12.4695 17.2501 12.5066C16.243 12.5437 15.2575 12.8086 14.3676 13.2814C13.4777 13.7542 12.7066 14.4226 12.1122 15.2363C11.5177 16.0501 11.1155 16.988 10.9358 17.9795C10.756 18.9711 10.8034 19.9905 11.0743 20.9611C11.3452 21.9317 11.8327 22.8282 12.5 23.5834" stroke="#0760EA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_5_8886">
                        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                    </clipPath>
                </defs>
            </svg>
            <Typography variant="body1" color={isDragActive ? 'primary.main' : 'text.secondary'} sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px', paddingTop: '10px' }}>{text}</Typography>
        </Paper>
    );
};

export default FileUpload;

import AddCompetitors from "./steps/AddCompetitors";
import ConfigureWorkspace from "./steps/ConfigureWorkspace/ConfigureWorkspace";
import CreateOrganization from "./steps/CreateOrganization";
import CreateWorkspace from "./steps/CreateWorkspace";
import SelectPlan from "./steps/SelectPlan/index.js";
import ConnectWordpress from "./steps/ConnectWordpress";
import CompletePayment from "./steps/CompletePayment/index";

export function getStepComponent(compName) {
  switch (compName) {
    case "StepOne":
      return <SelectPlan />;
    case "StepTwo":
      return <CreateOrganization />;
    case "StepThree":
      return <CreateWorkspace />;
    case "StepFour":
      return <ConfigureWorkspace />;
    case "StepFive":
      return <AddCompetitors />;
    case "StepSix":
      return <ConnectWordpress />
    case "StepSeven":
      return <CompletePayment />;

    default:
      return null;
  }
}

const onBoardingSteps = [
  {
    label: "Select Pricing",
    comp: "StepOne",
  },

  {
    label: "Create Organization",
    comp: "StepTwo",
  },

  {
    label: "Create Workspace",
    comp: "StepThree",
  },
  {
    label: "Configure Workspace",
    comp: "StepFour",
  },
  {
    label: "Add Competitors",
    comp: "StepFive",
  },
  {
    label: "Connect WordPress",
    comp: "StepSix",
  },
  {
    label: "Complete Payment",
    comp: "StepSeven",
  },
  // {
  //   label: "Terms and Conditions",
  //   comp: "StepSeven",
  // },
];
export function CSVToJSObject(csvData) {
  const headerCols = ["customerName", "siteLink"];

  const otherRows = csvData.split("\r\n");

  const data = [];
  for (let i = 0; i < otherRows.length; i++) {
    const otherRow = otherRows[i];
    const otherRowCols = otherRow.split(",");

    const tempData = {};
    for (let j = 0; j < otherRowCols.length; j++) {
      const element = otherRowCols[j];

      tempData[headerCols[j]] = element.trim();
    }

    data.push(tempData);
  }

  return data;
}

export { onBoardingSteps };

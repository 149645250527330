import * as React from "react";
import Button from "@mui/material/Button";

export default function CustomButton({ text, disable, onClick }) {
  return (
    <Button
      variant="contained"
      disabled={disable}
      onClick={onClick}
      fullWidth
      sx={{
        borderRadius: "12px",
        textTransform: "none",
        backgroundColor: "rgb(7, 96, 234)",
      }}
    >
      {text}
    </Button>
  );
}

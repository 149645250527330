import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { AUTH_ENDPOINTS } from "./actions.constants";

export const checkAutoLogin = () => {
  try {
    const jwt = localStorage.getItem("token");
    if (!jwt) return false;
    const decodedJwt = JSON.parse(atob(jwt.split(".")[1]));
    if (decodedJwt.expiry * 1000 < Date.now()) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const loginUser = async (payload) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.LOGIN,
      METHODS.POST,
      payload
    );
    console.log(response);
    if (!response || !response.data) {
      throw new Error(`Something wen't wrong during logging in!`);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerUser = async (payload) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.REGISTER,
      METHODS.POST,
      payload
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response;
  } catch (error) {
    console.error(`registerUser > ${error.toString()}`);
    throw error;
  }
};

export const verifyUserEmail = async (token) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.VERIFY,
      METHODS.PATCH,
      null,
      { Authorization: `Bearer ${token}` }
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during verification of email!`);
    }
  } catch (error) {
    console.error(`verifyUserEmail > ${error.toString()}`);
    throw error;
  }
};

export const forgotUserPassword = async (email) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.FORGOT_PASSWORD,
      METHODS.POST,
      { email }
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during forgot password!`);
    }
  } catch (error) {
    console.error(`forgotUserPassword > ${error.toString()}`);
    throw error;
  }
};

export const resetUserPassword = async (password, token) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.RESET_PASSWORD,
      METHODS.PATCH,
      { password },
      { Authorization: `Bearer ${token}` }
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during forgot password!`);
    }
  } catch (error) {
    console.error(`forgotUserPassword > ${error.toString()}`);
    throw error;
  }
};

export const getUser = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_ENDPOINTS.USER,
      METHODS.GET,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response || !response.data) {
      throw new Error(`Something wen't wrong while fetching details!`);
    }
    return response.data;
  } catch (error) {
    console.error(`getUser > ${error.response.data.error}`);
    throw Error(error.response ? error.response.data.error : error.message);
  }
};

export const updateUserProfile = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_ENDPOINTS.PROFILE,
      METHODS.PATCH,
      payload,
      { Authorization: `Bearer ${token}` }
    );
    if (!response || !response.data) {
      throw new Error(`Something wen't wrong while fetching details!`);
    }
    return response.data;
  } catch (error) {
    console.error(`updateUserProfile > ${error.response.data.error}`);
    throw Error(error.response ? error.response.data.error : error.message);
  }
};

export const onboardingService = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_ENDPOINTS.ONBOARD,
      METHODS.POST,
      { payload },
      { Authorization: `Bearer ${token}` }
    );
    if (!response || !response.data) {
      throw new Error(`Something wen't wrong while fetching details!`);
    }
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(`Onboarding User Failed > ${error.response.data.error}`);
    throw Error(error.response ? error.response.data.error : error.message);
  }
};

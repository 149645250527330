import Box from "@mui/material/Box";
import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import Grid from "@mui/material/Grid";
import StepChanger from "../StepChanger";
import { Controller, useFormContext } from "react-hook-form";

export default function CreateWorkspace() {
  const formMethods = useFormContext();

  console.log('form state errors', formMethods.formState.errors);

  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3>Create WorkSpace</h3>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="workspace.name"
              render={({ field }) => (
                <CustomTextField
                  placeholder="Your website name"
                  sx={{ paddingBottom: "20px" }}
                  label="Workspace Name"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  {...field}
                  error={!!formMethods.formState.errors?.workspace?.name}
                  helperText={
                    formMethods.formState.errors?.workspace?.name?.message
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="workspace.siteurl"
              render={({ field }) => (
                <CustomTextField
                  placeholder="www.example.com"
                  sx={{ paddingBottom: "20px" }}
                  label="WorkSpace Url"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  {...field}
                  error={!!formMethods.formState.errors?.workspace?.siteurl}
                  helperText={
                    formMethods.formState.errors?.workspace?.siteurl?.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <StepChanger />
      </Box>
    </Grid>
  );
}

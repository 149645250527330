import { Navigate } from "react-router-dom";

import Login from "../views/login";
import Onboarding from "../views/onboarding";
import Dashboard from "../views/dashboard";
import LoginPage from "../views/authentication/login/login.container";
import Register from "../views/authentication/register/register.container";
import VerifyEmail from "../views/authentication/register/verifyEmail";
import ResetPassword from "../views/authentication/resetPassword/resetPassword.container";
import UpdatePassword from "../views/authentication/updatePassword/updatePassword.container";
import BlanKLayout from "../layout/blank-layout/BlankLayout";
import FulLayout from "../layout/full-layout/logo/";
import Loader from "../views/authentication/loader/loader.container";

const Router = (isLoggedIn, onboarded) => [
  {
    path: "/",
    element: (
      <>
        {isLoggedIn && onboarded ? (
          <Navigate to="/dashboard/mainDashboard" />
        ) : isLoggedIn && !onboarded ? (
          <Navigate to="/dashboard/onboard" />
        ) : (
          <Navigate to="/auth/login" />
        )}
      </>
    ),
  },
  {
    path: "auth",
    element: <BlanKLayout />,
    children: [
      { path: "register", element: <Register /> },
      { path: "login", element: <LoginPage /> },
      { path: "verifyemail", element: <VerifyEmail /> },
      { path: "verify/:id", element: <Loader /> },
      { path: "verify/:id/:email", element: <UpdatePassword /> },
      { path: "reset-password", element: <ResetPassword /> },
    ],
  },
  {
    path: "dashboard",
    element: isLoggedIn ? <BlanKLayout /> : <Navigate to="/auth/login" />,
    children: [
      { path: "mainDashboard", element: <Dashboard /> },
      { path: "onboard", element: <Onboarding /> },
    ],
  },
];

export default Router;

import * as React from 'react';
import Alert from '@mui/material/Alert';

function MuiAlert(props) {
    const { type, data, action, icon } = props;
    return <Alert
        severity={type}
        action={action}
        icon={icon}
    >{data}</Alert>
}

export default MuiAlert;
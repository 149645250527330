import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";

import CustomSlider from "../../../components/forms/custom-elements/mySlider";
import PageContainer from "../../../components/container/PageContainer";
import Logo from "../../../assets/images/icon/Mail sent-rafiki.png";

import Background from "../../../assets/images/icon/Group 48096129.png";
import Child1 from "../../../assets/images/icon/image 2.png";

// styles
const styles = {
  paperContainer: {
    backgroundImage: `url(${Background})`,
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  heading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  heading2: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "0px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  paragraph: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "40px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  img: {
    textAlign: "center",
    width: "70%",
    height: "70%",
    display: "block",
    margin: "auto",
    marginTop: "30px",
  },
};

const Register = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <PageContainer
      title="Login - Edaly App"
      description="Edaly Application Login Page"
    >
      <Grid
        container
        spacing={0}
        sx={{
          background: "white",
        }}
      >
        <Grid item xs={12} sm={12} lg={6} style={{ height: "100vh" }}>
          <Grid
            spacing={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            item
            xs={12}
            lg={9}
            xl={8}
          >
            <Box sx={{ mt: "100px" }}>
              <img
                src={Logo}
                style={{ width: "40%", display: "flex", margin: "auto" }}
                alt="logo"
              />
              <Typography
                color="black"
                variant="h1"
                fontWeight="600"
                fontSize="35px"
                style={{ textAlign: "center" }}
                sx={{
                  mr: 1,
                }}
              >
                Verify Email
              </Typography>

              <Typography
                color="gray"
                variant="h3"
                fontWeight="500"
                fontSize="20px"
                style={{ textAlign: "center", marginTop: "30px" }}
                sx={{
                  mt: 1,
                }}
              >
                Please check your inbox and spam as well to verify your email.
              </Typography>
              <Link to={"/auth/login"} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    width: "100%",
                    marginTop: "40px",
                    background: "#0760EA",
                    height: "3rem",
                    borderRadius: "10px",
                  }}
                >
                  Go to Onboarding
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          style={styles.paperContainer}
          sx={{
            background: (theme) =>
              `${theme.palette.mode === "dark" ? "#1c1f25" : "#ffffff"}`,
          }}
        >
          <Box sx={{ mt: 8 }}>
            <CustomSlider {...settings}>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
              <div>
                <h1 style={styles.heading}>Globalizing</h1>
                <h1 style={styles.heading2}>Eduaction with AI</h1>
                <p style={styles.paragraph}>
                  Praesent fermentum amet augue ultricies amet elementum
                  adipiscing <br /> ligula in. Eget donec odio non tempus
                  aliquam tortor. Purus cursus
                  <br /> diam tortor pellentesque cum amet at vel.
                </p>
              </div>
            </CustomSlider>
            <br />
            <img src={Child1} style={styles.img} alt="child img" />
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Register;

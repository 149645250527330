import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Container from "@mui/system/Container";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import Paper from "@mui/material/Paper";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

import { FormControl, InputLabel, Select } from "@mui/material";

import BreadCrumb from "./BreadCrumb";
import Credit from "./Credit";
import Profile from "./Profile";

// Create the styles object
const styles = {
  welcomeCaption: {
    color: "rgba(81, 93, 109, 1)",
  },
  homeTitle: {
    color: "black",
    fontWeight: 500,
  },
  creditContainer: {
    margin: 1,
    display: "flex",
    bgcolor: "#0760EA",
    textAlign: "center",
    borderRadius: "12px",
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "108px",
    padding: 1,
  },
  creditIcon: {
    marginRight: 5,
  },
  creditText: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0px",
    textAlign: "center",
  },

  dropdownIcon: {
    color: "black",

    cursor: "pointer",
  },
  menu: {
    mt: "45px",
    p: 3,
    "& .MuiMenu-paper": {
      borderRadius: "12px",
    },
  },
  menuGreeting: {
    fontWeight: "bold",
    p: 2,
    paddingRight: "120px",
    borderBottom: "1px solid lightgray",
  },
  workspace: {
    "& .MuiOutlinedInput-root": {
      background: "#fff",

      height: "51px",
      top: "2px",
      left: "6px",
      borderRadius: "12px",
      gap: "12px",
      bgcolor: "#fff",
      borderBottom: 0,
    },
    "& .Mui-focused": {
      bgcolor: "#fff",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarContainer: {
    border: "1px solid",
    borderRadius: 999,
    borderColor: "#E2E8F0",
    padding: 1,
    height: "26px",
    width: "26px",
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    width: "12px",
    height: "12px",
  },
};

function Header(props) {
  const navigate = useNavigate();
  const { step, menu } = props;

  const settings = [
    { title: "Settings", icon: <SettingsIcon /> },
    { title: "Logout", icon: <LogoutIcon /> },
  ];

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <Container id="container" sx={{ width: "100%" }}>
      <Grid container sx={{ width: "100%", marginTop: "20px" }}>
        {/* Greeting and position */}
        <Grid item xs={4}>
          <BreadCrumb styles={styles} step={step} />
        </Grid>
        {/* Credit Workspace Profile */}
        <Grid
          container
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={8}
        >
          {/* Credit */}
          <Grid item sx={styles.gridItem} xs={2}>
            <Credit styles={styles} />
          </Grid>

          {/* Workspace */}
          <Grid item sx={styles.gridItem} xs={4}>
            <FormControl variant="outlined" sx={styles.workspace} fullWidth>
              <InputLabel> My Workspace </InputLabel>
              <Select>
                <MenuItem>Workspace 1</MenuItem>
                <MenuItem>Workspace 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Profile */}
          <Grid item sx={[styles.gridItem, { ml: 9 }]} xs={2}>
          <Profile settings={settings} menu={menu} logout={logout} styles={styles} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Header;

import Box from "@mui/material/Box";
import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import Grid from "@mui/material/Grid";
import StepChanger from "../StepChanger";
import { Controller, useFormContext } from "react-hook-form";

export default function CreateOrganization() {
  const formMethods = useFormContext();

  console.log(formMethods.formState.errors);

  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3>Create Organization</h3>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="company.name"
              render={({ field }) => (
                <CustomTextField
                  placeholder="Google"
                  sx={{ paddingBottom: "20px" }}
                  label="Organization Name"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  {...field}
                  error={!!formMethods.formState.errors?.company?.name}
                  helperText={formMethods.formState.errors?.company?.name?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <StepChanger />
      </Box>
    </Grid>
  );
}

import React, { useState, useRef } from 'react';
import "./uploader.css";
import CoverImagePNG from "../../../assets/images/users/cover.png"

function CoverPhotoUploader() {
    const coverPhotoUploader = useRef(null);
    const [coverImage, setCoverImage] = useState(CoverImagePNG);

    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setCoverImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div style={{ backgroundImage: `url(${coverImage})`, backgroundPosition: 'center', height: '158px', cursor: 'pointer' }} onClick={() => coverPhotoUploader.current.click()}>
            <input
                accept="image/*"
                id="cover-image-upload"
                type="file"
                style={{ display: 'none' }}
                ref={coverPhotoUploader}
                onChange={handleCoverImageChange}
            />
        </div>
    );
}

export default CoverPhotoUploader;
import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function PlanCard({selectedPlan, plan, key, onPlanChange}) {
  return (
    <Card
    key={key}
    sx={{
      backgroundColor:
        selectedPlan === plan.id ? "rgb(7, 96, 234)" : "transparent",
      mb: 2,
      borderRadius: 4,
    }}
    onClick={() => onPlanChange(plan.id)}
  >
    <CardContent>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item alignItems="center" spacing={4} xs={8}>
          <Grid item>
            {selectedPlan === plan.id ? (
              <CheckCircleIcon
                style={{ fontSize: "25px", color: "#fff" }}
              />
            ) : (
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                  border: "2px solid gray",
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color={selectedPlan === plan.id ? "white" : "black"}
              fontWeight="bold"
            >
              {plan.planName}
            </Typography>
            {/* <Typography
              variant="caption"
              color={selectedPlan === plan.id ? "whitesmoke" : "black"}
              sx={{
                marginTop: 1,
                backgroundColor:
                  selectedPlan === plan.id
                    ? "rgba(255,255,255,.1)"
                    : "rgba(7, 96, 234, .2)",
                padding: "7px 12px",
                display: "block",
                width: "fit-content",
                borderRadius: 10,
              }}
            >
              Save 10%
            </Typography> */}
          </Grid>
        </Grid>
        <Grid item alignItems="center">
          <Typography
            variant="h4"
            color={selectedPlan === plan.id ? "white" : "black"}
            fontWeight="bold"
            display="inline"
          >
            ${plan.price}
          </Typography>
          <Typography
            variant="caption"
            color={selectedPlan === plan.id ? "white" : "black"}
          >
            /mo
          </Typography>
        </Grid>
      </Grid>

      {selectedPlan === plan.id ? (
        <Card style={{ marginTop: "30px" }} sx={{ borderRadius: 4 }}>
          <CardContent sx={{ pl: 4 }}>
            {plan.PlanItem?.map((feature, key) => (
              <Grid
                key={key}
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography variant="subtitle1">
                  {feature.Feature.featureName}
                </Typography>
                <CheckCircleIcon
                  style={{ fontSize: "25px", color: "rgb(7, 96, 234)" }}
                />
              </Grid>
            ))}
          </CardContent>
        </Card>
      ) : null}
    </CardContent>
  </Card>
  )
}

import React, { useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";

const UndetectableAiMode = () => {
  const [undetectableAiEnabled, setUndetectableAiEnabled] = useState(false);

  const handleUndetectableAiChange = () => {
    setUndetectableAiEnabled((prevEnabled) => !prevEnabled);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={undetectableAiEnabled}
          onChange={handleUndetectableAiChange}
          sx={{
            height: 52,
            width: 90,
            "& .MuiSwitch-thumb": {
              width: 50,
              height: 35,
              borderRadius: 12,
              backgroundColor: undetectableAiEnabled ? "#0760EA" : "#0760EA", // Green when enabled, red when disabled
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& .undetectable-ai-text": {
                color: "white",
                fontSize: 14,
                fontWeight: "bold",
                margin: "0 6px",
              },
            },
            "& .MuiSwitch-track": {
              borderRadius: 12,
              background: "#fff",
              border: 2,
              borderColor: undetectableAiEnabled ? "#0760EA" : "#0760EA", // Green when enabled, red when disabled
            },
          }}
        />
      }
      label={<span className="undetectable-ai-text">Undetectable AI</span>}
      labelPlacement="start"
    />
  );
};

export default UndetectableAiMode;

import { connect } from "react-redux";
import { verify } from "../../../app/auth/auth.actions";
import LoaderComponent from "./loader";

const mapStateToProps = (state) => {
  return {
    isloading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  verify: (token, navigate) => {
    dispatch(verify(token, navigate));
  },
});

const LoaderPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderComponent);
export default LoaderPage;

import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function ParticularDetail({label, value}) {
  return (
    
    <Grid container>
            <Grid item xs={6}>
              <Typography
              sx={{fontSize:'18px', fontWeight:'24px'}}
              variant='caption'>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'right'}>
            <Typography
              sx={{
                textAlign:'right',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0em',
                
                }}
              variant='caption'>
                {value}
              </Typography>
            </Grid> 
          </Grid>
  )
}

import { Box, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form';

export default function SuggestedKeywords() {
  const formMethods = useFormContext();
    useEffect(()=>{
      console.log("test", formMethods.getValues())
    },[])
    const suggestions = ["programming", "gaming", "productivity"];

    const styles = {
      root: {
        width:'100%',
        backgroundColor: "#0760EA",
        borderRadius: "8px",
        padding: 1,
      },
      caption: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "26px",
        color: "#fff",
      },
      chip: {
        cursor: "pointer", // Add this to make the cursor a pointer on hover
        bgcolor: "#fff",
      },
    };
  
 
  
    return (
      <Box sx={styles.root}>
        <Typography
          sx={{
            ...styles.caption,
          }}
          variant="caption"
        >
          Suggested keywords
        </Typography>
        <Grid sx={{pt:2, pb:2}} container gap={1}>
          {suggestions.map((data, index) => (
            <Grid item key={index}>
              <Chip sx={styles.chip} label={data} />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
}

import disposable from "./disposableDomain";

export function validateEmail(email) {
  const [_, domain] = email.split("@");
  const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailFormat.test(email)) {
    return false;
  }

  if (disposable.includes(domain)) {
    return false;
  }

  if (email.split("@").length - 1 !== 1) {
    return false;
  }

  return true;
}

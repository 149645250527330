import { Box, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as CreditIcon } from "../../../assets/images/icon/credit_white.svg";

export default function Credit({styles}) {
  return (
    <Box sx={styles.creditContainer}>
    <CreditIcon style={styles.creditIcon} />
    <Typography sx={styles.creditText}>1000</Typography>
  </Box>
  )
}

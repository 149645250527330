import { Container, Paper, } from '@mui/material'
import React from 'react'

export default function PostSchedule() {
  const styles = {
    container: { marginTop: "5rem" },
    row: {
      padding: "26px",
      display: "flex",
      alignItems: "center",
    },
    heading: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#16182B",
    },
    tagline: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#515D6D",
    },

  };
  return (
    <Container sx={styles.container}>
    <Paper elevation={3} sx={{p:'12px'}}>
      
    </Paper>
    </Container>
  )
}

import React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled((props) => <TextField {...props} />)(({ theme }) => ({
  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"}`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12) " : "#f8f9fb "}`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-root": { // for figma design look
    background: "#fff",
    borderRadius: "12px",
    gap: "12px",
    bgcolor: "#fff",
    borderBottom: 0,
  },
}));

export default CustomTextField;

import { Grid } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../../components/forms/custom-elements/customTextField";
import CustomButton from "../../../components/forms/custom-elements/customButton";

export default function PromoCode() {
  const [trial, setTrial] = useState(true);
  return (
    <Grid spacing={1} container sx={{alignItems:'center'}}>
      <Grid item xs={9}>
        <CustomTextField 
        sx={{width:'100%'}}
        placeholder="Promo code" />
      </Grid>
      <Grid item xs={3}>
        <CustomButton text={"Apply Promocode"} />
      </Grid>
    </Grid>
  );
}

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";
import EmailVerification from "../EmailVerification/EmailVerification";
import { Box } from "@mui/material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const LoaderComponent = ({ isloading, verify }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBags = async () => {
      try {
        verify(id, navigate);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBags(id);
  }, []);

  return (
    <div>
      {isloading ? (
        <Box sx={{ display: "flex",height:'100vh', justifyContent:'center', alignItems:'center' }}>
          <ClipLoader
            loading={isloading}
            cssOverride={override}
            size={300}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      ) : (
        <>
          <EmailVerification />
          {/* <ClipLoader
            loading={isloading}
            cssOverride={override}
            size={300}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> */}
        </>
      )}
    </div>
  );
};

export default LoaderComponent;

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StepChanger from "../StepChanger";
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CustomTextField from "../../../../components/forms/custom-elements/customTextField";
import { Controller, useFormContext } from "react-hook-form";
import SecurityText from "../../../../components/onboarding/connect_wordpress/SecurityText";

export default function WordpressIntegration() {
  const formMethods = useFormContext();

  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3 style={{ marginBottom: "0px" }}>Wordpress Integration</h3>
        <br />
        <SecurityText />
        
        <br></br>
        <br></br>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="wordpress.email"
              render={({ field }) => (
                <CustomTextField
                  placeholder="John@email.com"
                  sx={{ paddingBottom: "20px" }}
                  label="Username or email"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="wordpress.password"
              render={({ field }) => (
                <CustomTextField
                  placeholder="******"
                  sx={{ paddingBottom: "20px" }}
                  label="Password"
                  type="password"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <StepChanger />
      </Box>
    </Grid>
  );
}

import { connect } from "react-redux";
import { resetPassword } from "../../../app/auth/auth.actions";
import UpdatePassword from "./updatePassword";

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    message: state.auth.error,
    showAlert: state.auth.showAlert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  Password: (password, token, navigate) => {
    dispatch(resetPassword(password, token, navigate));
  },
});

const UpdatePasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword);
export default UpdatePasswordPage;

// src/components/AvatarUploader.js
import React, { useState, useRef } from 'react';
import { Avatar, IconButton, Typography } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import UserImageJGP from "../../../assets/images/users/user.jpg"

function AvatarUploader(props) {
    const { icon, id } = props;
    const imageInput = useRef(null);
    const [profileImage, setProfileImage] = useState(UserImageJGP)

    const handleInput = () => {
        imageInput.current.click();
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setProfileImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <div style={{ float: 'left', zIndex: -1 }}>
                <Avatar
                    alt="Profile Picture"
                    src={profileImage}
                    sx={{ width: 120, height: 120, marginTop: '-60px', border: '3px solid white' }}
                />
                <IconButton color="primary" component="span">
                    <PhotoCameraIcon sx={{
                        backgroundColor: 'rgba(7, 96, 234, 1)',
                        padding: '10px',
                        borderRadius: '100px',
                        marginLeft: '60px',
                        marginTop: '-70px',
                        color: 'white',
                        marginRight: '10px',
                        border: '3px solid white'
                    }}
                        onClick={handleInput}
                    />
                    <input
                        accept="image/*"
                        id="profile-image-upload"
                        type="file"
                        style={{ display: 'none' }}
                        ref={imageInput}
                        onChange={(e) => handleProfileImageChange(e)}
                    />
                </IconButton>
            </div>
            <div>
                <Typography variant="h5">Alex James <span><svg width="18" height="18" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {icon ? <path d="M8 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5ZM10 19L13.1014 18.1139C13.2499 18.0715 13.3241 18.0502 13.3934 18.0184C13.4549 17.9902 13.5134 17.9558 13.5679 17.9158C13.6293 17.8707 13.6839 17.8161 13.7932 17.7068L20.25 11.25C20.9404 10.5597 20.9404 9.44034 20.25 8.74997C19.5597 8.05963 18.4404 8.05964 17.75 8.74999L11.2932 15.2068C11.1839 15.3161 11.1293 15.3707 11.0842 15.4321C11.0442 15.4866 11.0098 15.5451 10.9816 15.6066C10.9497 15.6759 10.9285 15.7501 10.8861 15.8987L10 19Z" stroke="#0760EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ cursor: 'pointer' }} /> : ''}
                </svg></span></Typography>
                {id ? <Typography sx={{
                    color: 'rgba(81, 93, 109, 1)'
                }}>SD#1235</Typography> : ''}
            </div>
            <br />
        </div >
    );
}

export default AvatarUploader;

import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import UndetectableAiMode from "../../../components/dashboard/create_article/UndetectableAI";
import CustomButton from "../../../components/forms/custom-elements/customButton";
import CustomTextField from "../../../components/forms/custom-elements/customTextField";
import SuggestedKeywords from "../../../components/dashboard/create_article/SuggestedKeywords";
import AddService from "../../../components/dashboard/create_article/AddService";
import {ReactComponent as Credit} from '../../../assets/images/icon/credit.svg'

export default function CreateArticle() {
  const styles = {
    container: { marginTop: "5rem" },
    row: {
      padding: "26px",
      display: "flex",
      alignItems: "center",
    },
    heading: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#16182B",
    },
    tagline: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#515D6D",
    },
    componentContainer: {
      width: "100%",
      marginTop: "16px",
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    },
    textField: {
      width: "100%",
      borderRadius: "12px",
      "& .MuiOutlinedInput-root": {
        background: "#fff",
  
        height: "51px",
        top: "2px",
        left: "6px",
        borderRadius: "12px",
        gap: "12px",
        bgcolor: "#fff",
        borderBottom: 0,
      },
    },
    formStyle: {
      "& .MuiOutlinedInput-root": {
        background: "#fff",
  
        height: "51px",
        top: "2px",
        left: "6px",
        borderRadius: "12px",
        gap: "12px",
        bgcolor: "#fff",
        borderBottom: 0,
      },
      "& .Mui-focused": {
        bgcolor: "#fff",
      },
   }
  };
  return (
    <Container sx={styles.container}>
      <Paper elevation={3} sx={{ padding: "24px" }}>
        {/* Header */}
        <Box sx={styles.header}>
          <Typography sx={styles.heading}>Create Article</Typography>
          <Typography sx={styles.tagline}>Generate Article Content</Typography>
        </Box>
        {/* End of Header */}

        {/* KeywordsField */}
        <Box sx={styles.componentContainer}>
          <CustomTextField
            placeholder="Enter Your Target Keywords"
            sx={styles.textField}
          />
        </Box>
        {/* End of KeywordsField */}

        {/* Suggested Keywords */}
        <Box sx={styles.componentContainer}>
          <SuggestedKeywords />
        </Box>
        {/* End of Suggested Keywords */}

        {/* Grid for topic,length, tone */}
        <Grid container spacing={2} sx={styles.componentContainer}>
          {/* topic */}
          <Grid item xs={4}>
            <CustomTextField
              placeholder="What should be the topic"
              sx={styles.textField}
            />
          </Grid>
          {/* length */}
          <Grid item xs={4}>
            <FormControl sx={styles.formStyle} fullWidth>
              <InputLabel>How long the article should be ?</InputLabel>
              <Select>
                <MenuItem value={100}>100 characters</MenuItem>
                <MenuItem value={100}>200 characters</MenuItem>
                <MenuItem value={100}>300 characters</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* Tone */}
          <Grid item xs={4}>
            <FormControl sx={styles.formStyle} fullWidth>
              <InputLabel>Select tone of article</InputLabel>
              <Select>
                <MenuItem>happy</MenuItem>
                <MenuItem>sad</MenuItem>
                <MenuItem>angry</MenuItem>
                <MenuItem>motivational</MenuItem>
                <MenuItem>inspiring</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Language Type Undetectable Ai mode */}
        <Grid spacing={2} container sx={styles.componentContainer}>
          {/* Language */}
          <Grid item xs={4}>
            <CustomTextField
              sx={styles.textField}
              placeholder="Which language you want article in ?"
            />
          </Grid>
          {/* Type */}
          <Grid item xs={4}>
            <FormControl sx={styles.formStyle} fullWidth>
              <InputLabel>Select the article type</InputLabel>
              <Select>
                <MenuItem>Type 1</MenuItem>
                <MenuItem>Type 2</MenuItem>
                <MenuItem>Type 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{display:'flex', justifyContent:'center'}} xs={4} item>
            <UndetectableAiMode />
          </Grid>
        </Grid>
        {/* End of Language Type Undetectable Ai mode */}

        {/* Competitors Link */}
        <Box sx={styles.componentContainer}>
          <CustomTextField
            placeholder="Put a link to your competitor article to create better content "
            sx={styles.textField}
          />
        </Box>
        {/* End of Competitors Link */}

        {/* Add Service */}
        <Box sx={styles.componentContainer}>
        <AddService />
        </Box>
        {/* End of Add Service */}

        {/* credit required and do the magic */}
        <Grid container sx={styles.row} spacing={2}>
          <Grid sx={{display:'flex'}} item xs={8}>
          <Credit sx={{ml:1}} />
            <Typography sx={{ml:1}} variant="caption">  Credit Required: --</Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomButton text="Do the magic!" />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

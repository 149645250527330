import Grid from '@mui/material/Grid';
import Card from "../../../components/dashboard/card";
import { Container, Paper } from '@mui/material';
import { menu } from '..';

export default function Main({setItem}) {
    return (
     <Container sx={{ marginTop: "30px", padding: "16px" }} >
              <Paper elevation={3} sx={{padding: "16px",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",}}>
           <Grid container spacing={6} sx={{display:'flex', justifyContent:'space-evenly'}}> {
        
        menu.members[0].items.map((item) => {
            return (
            
            <Grid
            onClick={()=>{setItem(item.title)}}
            item xs={5}>
            <Card title={item.title} description={item.description} /> 
            </Grid>
            )
        })
        
    }
    </Grid>
    </Paper>
 </Container>       
     
    )
}
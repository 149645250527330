import * as React from 'react';
import { styled } from '@mui/material/styles';

import { Autocomplete, TextField, Chip } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0.5,
    margin: 0,
    borderColor: '#E2E8F0',
    alignItems: 'center',
  },
  chip: {
    backgroundColor: '#0760EA0A', // Adjusted background color
    color: '#2473EC',
    '& .MuiChip-deleteIcon': {
      color: '#0760EA',
    },
  },
};

export default function Tags() {
  const [selectedTags, setSelectedTags] = React.useState([]);

  const handleDelete = (tagToDelete) => {
    setSelectedTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const getChipStyle = (option) => {
    return (
      <Chip
        key={option}
        label={option}
        sx={styles.chip}
        onDelete={() => handleDelete(option)} // Call handleDelete with the tag to remove
      />
    );
  };

  return (
    <Autocomplete
      multiple
      sx={styles.root}
      id="tags-outlined"
      options={['faizan', 'rehan', 'usman', 'sharjeel', 'test', 'lorem ipsun']}
      filterSelectedOptions
      value={selectedTags}
      onChange={(event, newValue) => setSelectedTags(newValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => getChipStyle(option))
      }
      renderInput={(params) => (
        <TextField {...params} placeholder="Enter tags" />
      )}
    />
  );
}

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import VerticalLinearStepper from "../../../components/steps/vertifical";
import { useOnBoardingContext } from "../context";
import { getStepComponent, onBoardingSteps } from "../lib";

export default function Steps() {
  const { step } = useOnBoardingContext();

  return (
    <>
      <Grid item xs={4}>
        <Box
          sx={{
            border: "2px solid #E2E8F0",
            borderRadius: 1,
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <VerticalLinearStepper steps={onBoardingSteps} activeStep={step} />
        </Box>
      </Grid>
      {getStepComponent(onBoardingSteps[step].comp)}
    </>
  );
}

import { connect } from "react-redux";
import { login } from "../../../app/auth/auth.actions";
import Login from "./login";

const mapStateToProps = (state) => {
  return {
    loading:state.auth.loading,
    fetchedUser: state.auth.isLoggedIn,
    message: state.auth.error,
    showAlert: state.auth.showAlert,
    onboarded: state.auth.onboarded,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password) => {
    dispatch(login(email, password));
  },
});

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export default LoginPage;

import { combineReducers } from "redux";
import CustomizerReducer from "./customizer/reducer";
import PlanReducer from "./plan/reducer";
import { auth } from "../app/auth/auth.reducers";
import UserReducer from "./user/reducer";
import IndustryReducer from "./industry/reducer";
// import _ from "../views/onboarding/steps/2";

const RootReducers = combineReducers({
  CustomizerReducer,
  PlanReducer,
  auth,
  UserReducer,
  IndustryReducer  
});

export default RootReducers;

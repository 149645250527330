import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import Post from "../../../components/dashboard/my_posts/Post";
import PreviewPost from "../preview_post";

export default function MyPosts({ setItem, setData }) {
  const styles = {
    container: { marginTop: "30px", padding: "16px" },
  };
  const posts = [
    {
      image:
        "https://img.freepik.com/free-photo/red-black-supercar-with-word-supercar-side_1340-23413.jpg",
      title: "Red car",
      topic: "driving",
      keywords: ["car", "vehicle"],
      tone: "inspiring",
      type: "blog",
      subhead: "Exploring the beauty of red supercars",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    {
      image:
        "http://t3.gstatic.com/licensed-image?q=tbn:ANd9GcSazP3msjSZEyc-15Q063oRRRarrkaNr9YaRcNYxs9OAsyJk-EVojD5jsTVdDU7nXN8",
      title: "Exploring Mountain Peaks",
      topic: "travel",
      keywords: ["adventure", "mountains", "exploration"],
      tone: "awe-inspiring",
      type: "article",
      subhead: "Exploring the beauty of red supercars",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    {
      image:
        "https://images.unsplash.com/photo-1616036740257-9449ea1f6605?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3Vuc2V0JTIwYmVhY2h8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
      title: "Sunset at the Beach",
      topic: "nature",
      keywords: ["beach", "sunset", "serenity"],
      tone: "calming",
      type: "blog",
      subhead: "Exploring the beauty of red supercars",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    {
      image:
        "https://i.insider.com/556cbdec69bedd880ccba7ae?width=1000&format=jpeg&auto=webp",
      title: "Delicious World of Street Food",
      topic: "food",
      keywords: ["food", "culinary", "street"],
      tone: "mouthwatering",
      type: "article",
      subhead: "Exploring the beauty of red supercars",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    // Add more diverse posts here
  ];

  const handlePreviewPost = (data) => {
    setData(data)
    setItem("Preview Post");
  };

  return (
    <Container sx={styles.container}>
      <Grid container spacing={2}>
        {posts.map((post, index) => (
          <Grid onClick={()=>handlePreviewPost(post)} key={index} item xs={4}>
            <Post {...post} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

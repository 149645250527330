import React from 'react'
import StepChanger from "../StepChanger";
import { Box, Grid } from '@mui/material';
import AddCompetitorsInput from '../../../../components/onboarding/add_competitors/AddCompetitorsInputs';
import FileUpload from '../../../../components/forms/custom-elements/customFileUpload';

export default function AddCompetitors() {
  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3>Add competitors</h3>
        <AddCompetitorsInput />
        <br />
        <FileUpload />
        <StepChanger skipable={true} />
      </Box>
    </Grid>

  )
}

import axios from "axios";
import { GET_PLANS } from "../constants";
import { getBaseApi } from "../../config/Enviroment";


export const getPlans = () => {
  return (dispatch) => {
    axios.get(`${getBaseApi()}plan`).then((res) => {
      dispatch({
        type: GET_PLANS,
        plans: res.data,
      });
    });
  };
};

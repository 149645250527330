import { Box, ButtonBase, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

function KeywordSuggestion({setKeywords}) {
  const formMethods = useFormContext()  

  const styles = {
    root: {
      backgroundColor: "#0760EA",
      // height: "122px",
      borderRadius: "8px",
      padding: 1,
    },
    caption: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "26px",
      color: "#fff",
    },
    chip: {
      
      bgcolor: "#fff",
    },
  };

  const handleSuggestionClick = (value) => {
    // const keywordField = document.getElementById("keywordField");
    // if (keywordField) {
    //   keywordField.value += `${value},`;
    // }
    setKeywords(prevState => [...prevState, value])
  };

  return (
    <Box sx={styles.root}>
      <Typography
        sx={{
          ...styles.caption,
        }}
        variant="caption"
      >
        Suggested keywords
      </Typography>
      <Grid container gap={1}>
        {formMethods.getValues().selectedIndustry && formMethods.getValues().selectedIndustry.industryKeywords[0].keywords.map((data, index) => (
          <Grid item key={index} onClick={() => handleSuggestionClick(data)}>
            <ButtonBase
            sx={{borderRadius:4}}
            >
            <Chip sx={styles.chip} label={data} />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default KeywordSuggestion;

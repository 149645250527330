import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PageContainer from "../../components/container";
import Header from "../../components/header";
import Steps from "./steps";
import OnBoardingContextProvider from "./context";

function Onboarding() {
  const forms = [
    {
      textFields: ["Organization name", "Address", "Primary contact no."],
      selects: [{ label: "Type of Organization", items: ["Edaly"] }],
      fileUpload: true,
      buttons: [
        {
          text: "Back",
          disable: true,
        },
        {
          text: "Next",
          disable: false,
        },
      ],
    },
  ];

  return (
    <OnBoardingContextProvider>
      <PageContainer
        title={"Welcome To Edaly"}
        description={"Complete 7 steps process to get started"}
      >
        <Box
          sx={{
            borderBottom: "1px solid lightGray",
          }}
        >
          <Container maxWidth="lg">
            <Header user={"Faizan"} />
          </Container>
        </Box>
        <Container>
          <div
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 600,
                lineHeight: "40px",
                letterSpacing: "0em",
                paddingBottom: "10px",
              }}
            >
              Welcome To Rankify
            </Typography>
            <Typography sx={{ color: "rgba(113, 128, 150, 1)" }}>
              Complete 7 steps process to get started
            </Typography>
          </div>
          <Grid container spacing={5}>
            <Steps />
          </Grid>
        </Container>
      </PageContainer>
    </OnBoardingContextProvider>
  );
}

export default Onboarding;

import { Box, Typography } from '@mui/material'
import React from 'react'

function BreadCrumb({styles, step}) {
  return (
    <Box>
    <Typography sx={styles.welcomeCaption} variant="caption">
      Welcome back 👋
    </Typography>
    <Typography sx={styles.homeTitle} variant="h6">
      Home {">"}
      <span style={{ color: "rgba(7, 96, 234, 1)" }}>{step}</span>
    </Typography>
  </Box>
  )
}

export default BreadCrumb
import CustomButton from "../../../components/forms/custom-elements/customButton";
import { CircularProgress, Grid } from "@mui/material";
import { useOnBoardingContext } from "../context";
import { onBoardingSteps } from "../lib";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { blue } from "@mui/material/colors";
export default function StepChanger({ skipable, isTrial}) {

const loading = useSelector((state) => state.auth.loading);
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
  color: "white",
};

  const { step, onNext, onPrev, onFinish, onSkip  } = useOnBoardingContext();

  const isLastStep = useMemo(() => step === onBoardingSteps.length - 1, [step]);

  return (
    <Grid container style={{ paddingTop: "20px" }} spacing={3}>
      <Grid item xs={3}>
        <CustomButton text="Back" disable={step === 0} onClick={onPrev} />
      </Grid>
      <Grid item xs={skipable ? 6 : 9}>
        <CustomButton
          text={
            isLastStep ? (
              loading ? (
                <>
<CircularProgress
    size={24}
    sx={{
      color: blue[500],
    }}
  />
                </>
              ) : (
                isTrial ? "Start Trial" : "Pay Now"
              )
            ) : (
              "Next"
            )
          }
          onClick={isLastStep ? (loading ? undefined : onFinish) : onNext}
        />
      </Grid>
      {
          skipable && 
          <Grid item xs={3}>
          <CustomButton
          text="skip"
          onClick={onSkip}
          />
          </Grid>
        }
    </Grid>
  );
}

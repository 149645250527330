import axios from "axios";
import { getBaseApi } from "../../config/Enviroment";

export const userOnBoard = (data) => {
  return (dispatch) => {
    axios.get(`${getBaseApi()}user/onbaord`).then((res) => {
      return {};
    });
  };
};

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const mySlider = styled((props) => <Slider {...props} />)(() => ({
  "& .slick-dots li button:before": {
    content: "",
    height: "8px",
    width: "8px",
    borderRadius: "100%",
    color: "white",
    background: "#ffffff",
    opacity: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    fontSize: "0px",
    transition: "all 0.2s linear",
  },
  ".slick-dots li.slick-active button:before": {
    opacity: 1,
    content: "",
    width: "100%",
    height: "8px",
    background: "#ffffff",
    top: 0,
    bottom: 0,
    margin: "auto",
    borderRadius: "11px",
    color: "white",
  },

  ".slick-dots li": {
    width: "25px ",
  },
}));

export default mySlider;

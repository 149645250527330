import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StepChanger from "../StepChanger";
import ParticularDetail from "../../../../components/onboarding/complete_payment/ParticularDetail";
import PromoCode from "../../../../components/onboarding/complete_payment/PromoCode";
import { useOnBoardingContext } from "../../context";

export default function CompletePayment() {
  const { selectedPlan  } = useOnBoardingContext();
  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "2px solid #E2E8F0",
          borderRadius: 1,
          padding: "26px",
        }}
      >
        <h3>Complete Payment</h3>
        <Box
          sx={{
            borderRadius: "12px",
            border: 1,
            borderColor: "#E2E8F0",
            padding: "12px",
          }}
        >
          <ParticularDetail label={"Selected Plan"} value={selectedPlan.name} />
          <ParticularDetail label={"Billing Frequency"} value="Monthly" />
          <ParticularDetail label={"Plan Duration"} value="15 days" />
          <br />
          <br />
          <br />
          <ParticularDetail label={"Amount Due"} value={`$${selectedPlan.price}.00`} />
          <ParticularDetail label={"Discount"} value="$0.00" />
          <ParticularDetail label={"Promo Code"} value="$0.00" />
          <ParticularDetail label={"Total"} value="$0.00" />
          <br />
        </Box>
        <br />
        <PromoCode />
        <StepChanger isTrial={true} />
      </Box>
    </Grid>
  );
}

import { useState } from "react";

import PageContainer from "../../components/container";
import Main from "./main";
import Sidebar from "../../components/sidebar";

// SETTING
import Settings from "./settings";
import ProfileSettings from "./settings/type/profileSettings";
import ConfigurationSetting from "./settings/type/configurationSettings";
import { WorkspaceContextProvider } from "./settings/type/context";

import { ReactComponent as SidebarMenuIconOpen } from "../../assets/rankify/sidebar/menuIconOpen.svg";
import { ReactComponent as SidebarMenuIconClose } from "../../assets/rankify/sidebar/menuIconClose.svg";

// MUI ICONS
import CreateArticle from "./create_article";
import ScheduleArticles from "./schedule_articles";
import MyPosts from "./my_posts";
import PreviewPost from "./preview_post";
import {ReactComponent as CreateArticlesIcon } from '../../assets/images/icon/create_article.svg'
import {ReactComponent as ScheduleArticleIcon } from '../../assets/images/icon/schedule_article.svg'
import {ReactComponent as MyArticleIcon } from '../../assets/images/icon/my_article.svg'
import {ReactComponent as TeamIcon } from '../../assets/images/icon/team.svg'
import {ReactComponent as ConfigurationIcon } from '../../assets/images/icon/configuration.svg'
import {ReactComponent as UndetectableAiIcon } from '../../assets/images/icon/undetectable_ai.svg'


export const menu = {
  icon: {
    open: <SidebarMenuIconOpen />,
    close: <SidebarMenuIconClose />,
  },
  members: [
    {
      title: "Menu",
      items: [
        {
          title: "Create Article",
          icon: <CreateArticlesIcon />,
          description: "Option to generate new content.",
        },
        {
          title: "Schedule Articles",
          icon: <ScheduleArticleIcon />,
          description: "Facility to plan content posting.",
        },
        {
          title: "My Articles",
          icon: <MyArticleIcon />,
          description: "View and manage user's published posts.",
        },
        {
          title: "Team",
          icon: <TeamIcon />,
          description: "Manage and interact with team members.",
        },
        {
          title: "Configurations",
          icon: <ConfigurationIcon />,
          description: "Access settings and configurations.",
        },
        {
          title: "Undetectable AI",
          icon: <UndetectableAiIcon />,
          description: "Implementation of hard-to-detect AI technology.",
        },
      ],
    },
  ],
};

function Dashboard() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [item, setItem] = useState("Dashboard");
  const [data, setData] = useState();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (item) => {
    setItem(item);
    handleCloseUserMenu();
  };

  return (
    <PageContainer title={"Welcome To Rankify"} description={item}>
      <Sidebar
        menu={menu}
        step={item}
        headerMenu={{
          items: anchorElUser,
          close: handleCloseUserMenu,
          open: handleOpenUserMenu,
          click: handleMenuItemClick,
        }}
        handleItem={handleMenuItemClick}
      >
        {item === "Dashboard" ? <Main setItem={setItem} /> : ""}
        {item === "Settings" ? <Settings setItem={setItem} /> : ""}
        {item === "Personal Settings" ? (
          <WorkspaceContextProvider>
            <ProfileSettings />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
        {item === "Configurations" ? (
          <WorkspaceContextProvider>
            <ConfigurationSetting />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
        {item === "Create Article" ? (
          <WorkspaceContextProvider>
            <CreateArticle />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
        {item === "Schedule Articles" ? (
          <WorkspaceContextProvider>
            <ScheduleArticles />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
        {item === "My Articles" ? (
          <WorkspaceContextProvider>
            <MyPosts setItem={setItem} setData={setData} />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
        {item === "Preview Post" ? (
          <WorkspaceContextProvider>
            <PreviewPost data={data} />
          </WorkspaceContextProvider>
        ) : (
          ""
        )}
      </Sidebar>
      {/* <AppBar
        sx={{
          backgroundColor: "rgba(250, 250, 250, 1)",
          boxShadow: 0,
          zIndex: 1,
          paddingBottom: "20px",
          
        }}
        position="fixed"
      >
        
          <Header
            step={item}
            menu={{
              items: anchorElUser,
              close: handleCloseUserMenu,
              open: handleOpenUserMenu,
              click: handleMenuItemClick,
            }}
          />
        
      </AppBar> */}
    </PageContainer>
  );
}

export default Dashboard;

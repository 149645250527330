import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { userOnBoard } from "../../../../redux/workspace/action";

const onBoardingContextDefaultValues = {};

const OnBoardingContext = createContext(onBoardingContextDefaultValues);

export const useOnBoardingContext = () => useContext(OnBoardingContext);

export const WorkspaceContextProvider = ({ children }) => {
    const [step, setStep] = useState(0);
    const formMethods = useForm();
    const dispatch = useDispatch();

    const onNext = useCallback(() => {
        let isError = false;
        if (step === 0) {
            if (!formMethods.getValues("company.name")) {
                formMethods.setError("company.name", { message: "Organization Name is required" });
                isError = true;
            }
        }

        if (step === 2) {
            if (!formMethods.getValues("keywords") || formMethods.getValues("keywords")?.length === 0) {
                formMethods.setError("keywords", { message: "Keywords are required" });
                isError = true;
            }
            if (!formMethods.getValues("niche")) {
                formMethods.setError("niche", { message: "Niche is required" });
                isError = true;
            }
            if (!formMethods.getValues("summaryOfBusiness")) {
                formMethods.setError("summaryOfBusiness", { message: "Summary Of Business is required" });
                isError = true;
            }
            if (!formMethods.getValues("industry")) {
                formMethods.setError("industry", { message: "Industry is required" });
                isError = true;
            }
        }

        if (isError) return;

        setStep((prev) => prev + 1);
    }, [step]);

    const onPrev = useCallback(() => {
        setStep((prev) => prev - 1);
    }, []);

    const onFinish = useCallback(() => {
        dispatch(userOnBoard(formMethods.getValues()));
    }, []);

    const values = useMemo(
        () => ({
            // States
            step,
            // Functions
            onNext,
            onPrev,
            onFinish,
        }),
        [step, onNext, onPrev, onFinish]
    );

    return (
        <OnBoardingContext.Provider value={values}>
            <FormProvider {...formMethods}>{children}</FormProvider>
        </OnBoardingContext.Provider>
    );
};

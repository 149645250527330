import { Navigate } from "react-router-dom";
import { AUTH_ACTIONS } from "./actions.constants";
import { useNavigate } from "react-router-dom";
import {
  forgotUserPassword,
  getUser,
  loginUser,
  registerUser,
  resetUserPassword,
  updateUserProfile,
  onboardingService,
  verifyUserEmail,
} from "./auth.service";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.FETCHING_LOGIN });
    try {
      const payload = { email, password };
      const data = await loginUser(payload);
      localStorage.setItem("token", data.accessToken);
      return dispatch({ type: AUTH_ACTIONS.FETCHED_LOGIN, payload: data });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.LOGIN_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const register = (firstName, lastName, email, password, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.REGISTERING_USER });
    try {
      const payload = {
        firstName,
        lastName,
        email,
        password,
      };
      console.log(payload);
      const data = await registerUser(payload);
      await navigate("/auth/verifyemail", { replace: true });
      return dispatch({ type: AUTH_ACTIONS.USER_REGISTERED });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.REGISTRATION_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const verify = (token, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      await verifyUserEmail(token);
      await navigate("/");
      return dispatch({ type: AUTH_ACTIONS.VERIFIED });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.VERIFICATION_FAILED,
        payload: error.message,
      });
    }
  };
};

export const VerifyMember = (token, navigate) => {
  // const navigate = useNavigate();
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      await verifyUserEmail(token);
      navigate("/auth/member-signUp");
      return dispatch({ type: AUTH_ACTIONS.VERIFIED });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.VERIFICATION_FAILED,
        payload: error.message,
      });
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.FORGOT_PASSWORD_INITIATED });
    try {
      await forgotUserPassword(email);
      // navigate('/', { replace: true });
      return dispatch({ type: AUTH_ACTIONS.FORGOT_PASSWORD_DONE });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.FORGOT_PASSWORD_FAILED,
        payload: error.message,
      });
    }
  };
};

export const resetPassword = (password, token, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_STARTED });
    try {
      await resetUserPassword(password, token);
      await navigate("/auth/login");
      return dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_DONE });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.RESET_PASSWORD_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const setTempToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.SET_TEMP_TOKEN, payload: token });
  };
};

export const setToken = (token) => {
  return async (dispatch) => {
    localStorage.setItem("token", token);
    dispatch({ type: AUTH_ACTIONS.SET_TEMP_TOKEN, payload: token });
  };
};

export const resetStatus = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.RESET_STATUS });
  };
};

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.FETCHING_USER });
    try {
      console.log("Fetching User");
      const data = await getUser();
      if (data) {
        localStorage.setItem("userName", `${data.firstName} ${data.lastName}`);
      }
      return dispatch({ type: AUTH_ACTIONS.FETCHED_USER, payload: data });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.USER_FAILED,
        payload: error.message,
      });
    }
  };
};

export const update = (firstName, lastName, email) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.UPDATING_PROFILE });
    try {
      const payload = {
        firstName,
        lastName,
        email,
      };
      await updateUserProfile(payload);
      const data = await getUser();
      if (data) {
        localStorage.setItem("userName", `${data.firstName} ${data.lastName}`);
      }
      return dispatch({ type: AUTH_ACTIONS.UPDATING_DONE, payload: data });
    } catch (error) {
      console.error(error.message);
      return dispatch({
        type: AUTH_ACTIONS.UPDATING_FAILED,
        payload: error.message,
      });
    }
  };
};

export const onboarding = (payload, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.ONBOARDING_USER });
    try {
      const data = await onboardingService(payload);
      // navigate("/dashboard/mainDashboard", { replace: true });
      return dispatch({ type: AUTH_ACTIONS.ONBOARDED_USER });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.ONBOARDING_USER_FAILED,
        payload: error,
      });
    }
  };
};

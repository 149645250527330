import { GET_PLANS } from "../constants";

const INIT_STATE = {
  plans: [],
};

const PlanReducer = (state = INIT_STATE, action) => {
  console.log(action);
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        plans: [...action.plans.data],
      };
    default:
      return state;
  }
};

export default PlanReducer;
